<template>
  <div>
    <b-sidebar width="600px" id="ship-details" shadow>

      <div class="px-3 py-2">
        <b-badge class="mb-2" variant="light">DETAILS</b-badge>

        <b-card v-if="selected_row">
          <b-row class="mb-2">
            <b-col sm="3" class="text-left"><b>Device ID:</b></b-col>
            <b-col class="listsmall">{{selected_row.deviceId}}</b-col>
          </b-row>
          <b-row class="mb-2" v-if="selected_row.email">
            <b-col sm="3" class="text-left"><b>Email:</b></b-col>
            <b-col class="listsmall">{{selected_row.email}}</b-col>
          </b-row>
          <hr>
          <b-row class="mb-2" v-if="selected_row.primarInfo.shipInfo.name">
            <b-col sm="3" class="text-left"><b>Ship name:</b></b-col>
            <b-col v-if="selected_row.primarInfo && selected_row.primarInfo.shipInfo" class="listsmall">{{selected_row.primarInfo.shipInfo.name}}</b-col>
          </b-row>
          <b-row class="mb-2" v-if="selected_row.primarInfo.shipInfo.category">
            <b-col sm="3" class="text-left"><b>Category:</b></b-col>
            <b-col v-if="selected_row.primarInfo && selected_row.primarInfo.shipInfo" class="listsmall">{{selected_row.primarInfo.shipInfo.category}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-left"><b>Type:</b></b-col>
            <b-col class="listsmall">{{utils.getAppName(selected_row.applicationId)}}</b-col>
          </b-row>
          <b-row class="mb-2" v-if="selected_row.primarInfo.shipInfo.ecdisBrand">
            <b-col sm="3" class="text-left"><b>ECDIS brand:</b></b-col>
            <b-col v-if="selected_row.primarInfo && selected_row.primarInfo.shipInfo" class="listsmall">{{selected_row.primarInfo.shipInfo.ecdisBrand}}</b-col>
          </b-row>
          <b-row class="mb-2" v-if="selected_row.primarInfo.shipInfo.imo">
            <b-col sm="3" class="text-left"><b>IMO:</b></b-col>
            <b-col v-if="selected_row.primarInfo && selected_row.primarInfo.shipInfo" class="listsmall">{{selected_row.primarInfo.shipInfo.imo}}</b-col>
          </b-row>
          <b-row class="mb-2" v-if="selected_row.primarInfo.shipInfo.callSign">
            <b-col sm="3" class="text-left"><b>Callsign:</b></b-col>
            <b-col v-if="selected_row.primarInfo && selected_row.primarInfo.shipInfo" class="listsmall">{{selected_row.primarInfo.shipInfo.callSign}}</b-col>
          </b-row>
          <b-row class="mb-2" v-if="selected_row.primarInfo.primarPermit">
            <b-col sm="3" class="text-left"><b>Permit:</b></b-col>
            <b-col v-if="selected_row.primarInfo" class="listsmall">{{selected_row.primarInfo.primarPermit}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-left"><b>License:</b></b-col>
            <b-col class="listsmall">AXGF-SDGH-ERGS-AKFD</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-left"><b>License type:</b></b-col>
            <b-col class="listsmall">private</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-left"><b>License valid:</b></b-col>
            <b-col class="listsmall">24-Dec-2024</b-col>
          </b-row>
          <b-row class="mb-2" v-if="selected_row.primarInfo.notificationEmails">
            <b-col sm="3" class="text-left"><b>Notifications emails:</b></b-col>
            <b-col v-if="selected_row.primarInfo" class="listsmall">{{selected_row.primarInfo.notificationEmails}}</b-col>
          </b-row>
<!--          <b-row class="mb-2" v-if="selected_row.primarInfo.deliveryEmail"> hardcoded-->
          <b-row class="mb-2">
            <b-col sm="3" class="text-left"><b>Delivery email:</b></b-col>
            <b-col v-if="selected_row.primarInfo.deliveryEmail" class="listsmall">{{selected_row.primarInfo.deliveryEmail}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-left"><b>Charts:</b></b-col>
            <b-col v-if="selected_row.primarInfo" class="listsmall">
              <b-table id="orders-for-devices-table" striped sort-by="id" sort-icon-left :busy="is_busy" :items="visible_rows()" :fields="visible_fields">
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>&nbsp;{{$store.state.fickle.requests.current_request}}</strong>
                  </div>
                </template>
                <template #cell(created_date)="row">
                  {{ utils.formatDate(row.item.created_date).date }}
                </template>
                <template #cell(days_valid)="data">
                  <span v-if="data.value < 1" class="smt14">expired</span>
                  <span v-else :class="[data.value <= 28 ? 'smt14' : '']">{{ data.value }}</span>
                </template>
                <template #cell(band)="data">
                  <span class="small-gray">{{ data.value }}</span>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-sidebar>
  </div>
</template>


<script>
import * as utils from "@/utils/utils";
// import {daysToMonth, getAppName} from "@/utils/utils";
// import Charts from "@/views/transactions/sidebars/Charts.vue";
import {eventBus} from "@/main";
// import * as utils from "../../../utils/utils";

export default {
  name: "EcdisDetails",
  // components: {Charts},
  props: ["selected_row"],
  computed: {
    utils() {
      return utils
    },
    is_busy() {
      // return false
      return this.$store.state.fickle.orders_list.is_refresing
    },
    visible_fields() {
      return [
        {key: 'id', label: 'ID', sortable: true, tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'band', label: 'Band', sortable: true, tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'created_date', label: 'Ordered', sortable: true, tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'days_valid', label: 'Days valid', sortable: true, tdClass: 'small-black', thClass: 'small-gray'}
      ]
    }
  },
  mounted() {
    eventBus.$on('refresh-device-orders-table', () => {
      // alert(2)
    })
  },
  methods: {
    // getAppName,
    // temp() {
    //   let date = new Date()
    //   let days = utils.daysToMonth("2023-09-11T08:15:16.7460453Z", 3)
    //   return days
    // },
    visible_rows() {

      // let cells = []
      //
      // this.$store.state.fickle.orders_list.orders.forEach(order => {
      //   if (order.isPaid) {
      //     order.items.forEach(cell => {
      //       cells.push({id: cell.id})
      //     })
      //   }
      // })

      return this.$store.state.fickle.orders_list.cells
    },
    edit() {
      eventBus.$emit('edit-ecdis')
    }
  }
}
</script>

<style scoped>

</style>