<template>
  <b-nav-item :to="getTo()" :active-class="aclass" :tag="rltag">
    <slot/>
  </b-nav-item>
</template>

<script>
export default {
  props: {
    to: {default: null},
    aclass: {default: "active"},
    rltag: {type: String, default: "a"},
  },
  methods: {
    getTo() {
      let path = "";
      if (this.to) {
        if (this.to.name) {
          let props = this.$router.resolve({
            name: this.to.name,
            //params: { id: some_id, slug: some_slug },
          });
          path = props.href;
        } else if (typeof this.to === "string") {
          path = this.to;
        } else {
          return this.to;
        }
      }
      const locale = this.$route.params.locale;

      // we strip leading and trailing slashes and prefix
      // the current locale
      if (locale) return `/${locale}/${path.replace(/^\/|\/$/g, "")}`;
      return `/${path.replace(/^\/|\/$/g, "")}`;
    },
  },
};
</script>