export class PythonArray {
    constructor() {
        return new Proxy([], {
            get: function(target, name) {
                let index = parseInt(name, 10);

                // eslint-disable-next-line use-isnan
                if (name === NaN) {
                    if (name in target) return target[name];
                    else throw "Not a number";
                }

                // simple implementation
                if(index < 0)   return target[target.length + index];
                else if (index > target.length-1) return target[index%target.length]
                else    return target[index];
            }
        });
    }

    static from(arr){
        let pythonArray = new PythonArray();
        for(let i = 0; i < arr.length; i++){
            pythonArray[i] = arr[i];
        }
        return pythonArray;
    }
}