import {file} from 'paths.macro';
import store from "@/store";

export function authHeader() {
    let user = store.state.auth.user;
    // alert(user.token)
    if (user && user.token) {
        // if (process.env.NODE_ENV === "development") {
        //
        // }
        return { 'Authorization': 'Bearer ' + user.token };
    }
    else {
        return {};
    }
}