<template>
  <div>
    <b-modal v-model="images_modal" ok-only>
      <div class="col-9 col-sm-10 col-md-10 col-lg-10" style="overflow-y: scroll">
        <image-viewer :images=image_src></image-viewer>
      </div>
    </b-modal>

    <b-modal v-model="audio_modal" ok-only>
      <audio controls :src='audio_src'></audio>
    </b-modal>

    <b-modal v-model="messages_modal" ok-only>
      {{messages}}
    </b-modal>

    <h6 align="center" class="mt-2">LOGBOOK</h6>
    <div>
      <b-table selectable thead-class="d-none" select-mode="single" @row-selected="onRowSelected" :items="visibleRows()" :fields="visible_fields">
        <template #cell(image)="row">
          <b-button v-if="row.item.images" class="unstyled-button" size="sm" title="Images" @click="showImage(row.item)">
            <b-icon variant="dark" icon="card-image" aria-hidden="true"></b-icon>
          </b-button>
        </template>
        <template #cell(audio)="row">
          <b-button v-if="row.item.audio" class="unstyled-button" size="sm" title="Images" @click="playAudio(row.item)">
            <b-icon variant="dark" icon="mic" aria-hidden="true"></b-icon>
          </b-button>
        </template>
        <template #cell(messages)="row">
          <b-button v-if="row.item.info.message" class="unstyled-button" size="sm" title="Images" @click="showMessages(row.item)">
            <b-icon variant="dark" icon="file-earmark-text" aria-hidden="true"></b-icon>
          </b-button>
        </template>
        <template #cell(time)="data">
          {{getDate(data.value)}}
        </template>
      </b-table>

      <b-button size="sm" class="mr-2" variant="warning" @click="QRscan()">Upload more...</b-button>
      <b-button size="sm" variant="warning" @click="Report()">Report</b-button>


    </div>
  </div>
</template>

<script>
import {eventBus} from "@/main";
import router from "@/router";
import ImageViewer from "@/components/ImageViewer";
// import QRWindow from "@/components/qr/QRWindow";

export default {
  components: {ImageViewer},
  data() {
    return {
      images_modal: false,
      audio_modal: false,
      messages_modal: false,
      messages: "",
      image_src: "",
      audio_src: ""
    }
  },
  // components: {QRWindow},
  mounted() {
  },
  computed: {
    images() {
      return this.$store.state.fickle.qr.images.map((image) => ({ //TODO: get images
        src: image.src,
        thumbnail: image.thumbnail ? image.thumbnail : image.src, // Используйте src в качестве миниатюры, если thumbnail не указан
        originalSize: [image.width, image.height].join('x'), // Ширина и высота оригинального изображения
        alt: image.alt || '', // Альтернативный текст (пустая строка, если не указан)
        height: image.height,
        width:  image.width,
        name: image.name
      }));
    },
    visible_fields() {
      return [
        {key: 'time', label: 'Time', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'messages', label: '', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'image', label: '', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'audio', label: '', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
  },
  methods: {
    visibleRows() {

      let logs = this.$store.state.fickle.qr.shipLogs
      let objIndex = logs.findIndex((obj => obj.permit === this.$store.state.fickle.qr.selectedPermit));

      return this.$store.state.fickle.qr.shipLogs[objIndex].logs
    },
    onRowSelected(items) {
      if (items.length > 0) {
        // alert(items[0])
        // console.log(items[0])
        eventBus.$emit('show-log-on-map', items[0].time)
      }
    },
    showImage(row) {

      console.log(row)

      // this.image_src = this.$store.state.fickle.qr.shipLogs[0].logs[0].images[0].src
      // this.image_src = row.images[0].src
      this.image_src = row.images
      this.images_modal = true
    },
    playAudio(row) {

      console.log(row)

      this.audio_src = row.audio_src
      this.audio_modal = true

    },
    showMessages(row) {

      console.log(row)

      this.messages = row.info.message
      this.messages_modal = true

    },
    getDate(date) {

      let t = new Date(date)

      let hr = ("0" + t.getHours()).slice(-2);
      let min = ("0" + t.getMinutes()).slice(-2);
      let sec = ("0" + t.getSeconds()).slice(-2);

      return t.getFullYear()+"-"+parseInt(t.getMonth()+1)+"-"+t.getDate()+" "+hr+":"+min+":"+sec
    },
    QRscan() {
        router.push({name:'ships'})
        setTimeout(() => {
          eventBus.$emit('start-scan-qr-ecdis')
        }, 500)
    },
    Report() {
        router.push({name:'ships'})
        // setTimeout(() => {
        //   eventBus.$emit('start-scan-qr-ecdis')
        // }, 500)
    },

  }
}
</script>

<style scoped>
.unstyled-button {
  border: none;
  padding: 0;
  background: none;
}
</style>