<template>
  <div>
    <h6 align="center" class="mt-2">{{shipName()}}</h6>
    <div>
        <div id="mymap" ref="mm" :style="{ height: height + 'px'}"></div>
    </div>
  </div>
</template>

<script>
import {eventBus} from "@/main";
import store from "@/store";

export default {
  props: ["map", "height"],

  mounted() {
    this.map.init(this.$refs.mm);
  },
  methods: {
    shipName() {
      let logs = this.$store.state.fickle.qr.shipLogs
      let objIndex = logs.findIndex((obj => obj.permit === this.$store.state.fickle.qr.selectedPermit));
      let shipName = this.$store.state.fickle.qr.shipLogs[objIndex].logs[this.$store.state.fickle.qr.shipLogs[objIndex].logs.length - 1].info.name
      return shipName
    }
  }
}
</script>
<style scoped>
  #mymap{
    z-index: 1;
  }
</style>
