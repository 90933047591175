<template>
  <div>
<!--    <b-modal v-model="is_offline" title="Warning" ok-only>-->
<!--      <p>Internet connection is lost</p>-->
<!--    </b-modal>-->

  <b-container v-if="$store.state.fickle.offline.userDataLoading || $store.state.fickle.offline.encLoading" fluid>
    <div align="center" class="text-centercenter text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong>&nbsp;Loading...</strong>
    </div>
  </b-container>
  <b-container v-else fluid>
    <b-row>
      <b-col cols="12">
        <b-card header="ORDERS">
          <orders></orders>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  </div>
</template>

<script>
import Orders from "@/views/transactions/Orders.vue";
import store from "@/store";
import {checkInternet, checkInternetLoop, loadData, loadENCData, loadUserData, loadUserInstallations} from "@/helpers/offline";
import qrcode from "@/data/ship2.json";
import {logout} from "@/services/authService";
import {eventBus} from "@/main";
import {myAxios} from "@/services/myAxios";
export default {
  components: {Orders},
  data() {

    return {
      interval: null,
      // is_offline: false,
    }
  },
  created () {
    // window.addEventListener('online', () => console.log('xx Became online'));
    // window.addEventListener('offline', () => console.log('xx Became offline'));
    // window.addEventListener('online', this.a1);
    // window.addEventListener('offline', this.a2);

    // window.addEventListener("online", (event) => {
    //   console.log("xx You are now connected to the network.");
    // });

// ononline version
//     window.ononline = (event) => {
//       console.log("xx You are now connected to the network.");
//     };

  },

  destroyed: function () {
    // window.removeEventListener('online', () => console.log('xx Became online'));
    // window.removeEventListener('offline', () => console.log('xx Became offline'));
    // window.removeEventListener('online', () => console.log('xx Became online'));
    // window.removeEventListener('offline', () => console.log('xx Became offline'));

  },
  methods: {
    // a1() {
    //   console.log("a1")
    // },
    // a2() {
    //   console.log("a2")
    // }
  },
  mounted() {

    // window.addEventListener("online", function() {
    //   console.log("xx I am connected to the internet")
    // })
    //
    // window.addEventListener("offline", function() {
    //   console.log("xx Disconnected...so sad!!!")
    // })

    // console.log('xx Initially ' + (window.navigator.onLine ? 'on' : 'off') + 'line');

    // window.addEventListener('online', () => console.log('xx Became online'));
    // window.addEventListener('offline', () => console.log('xx Became offline'));

    // store.commit("fickle/setQRInfoDebug")

    if (this.$route.params.qrevent === 'reload-transactions') {
      loadUserData().then( resp => {
        console.log("off, INSTALLATIONS DONE")})
    }

    let qrcode = require("@/data/ship2.json")
    store.commit("fickle/setQRInfo", qrcode)

    // eventBus.$on('is-offline', (flag) => {
    //   this.is_offline = flag
    // })

    myAxios.get('/api/v1/manage/test')

    if (!store.state.fickle.offline.userInstallationsReady) {
      loadUserInstallations().then( resp => {
        console.log("off, INSTALLATIONS DONE")})
    }
    if (!store.state.fickle.offline.userDataReady) {
      loadUserData().then( resp => {
        console.log("off, USER DONE")})
    }
    if (!store.state.fickle.offline.encDataReady) {
      loadENCData().then( resp => {
        console.log("off, ENC DONE")})
    }

    // checkInternetLoop() // first run
    this.interval = setInterval(checkInternetLoop, 5000)

    console.log("MOUNT")
    // if (this.$store.state.charts.s63.products.all.length === 0) {
    //   this.$store.dispatch('getENCs2222').then(() => {
    //     console.log("ENC loaded")
    //   })
    // }

    // if (this.$store.state.proto.installationsECDIS[0] === "-1") {
    //   this.$store.dispatch("proto/getInstallations").then(() => {
    //     console.log("Ships loaded")
    //   })
    // }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }

}
</script>

<style scoped>
</style>