<template>
  <div>
    <b-modal ref="payment-modal" hide-footer title="Choice purchasing system">
      <b-button block class="mt-2" variant="danger" @click="hideModal()">Cancel</b-button>
      <b-button v-if="this.$store.state.current.webuser.location === 'RU'" block class="mt-2" variant="info" @click="payYoomoney()">Yoomoney {{ $store.state.proto.order.total_priceRUB }}₽</b-button>
      <b-button block class="mt-2" variant="info" @click="payStripe()">Stripe {{ $store.state.proto.order.total_price }} €</b-button>
    </b-modal>
  </div>
</template>

<script>
import {daysOldWithSign, getAppType, getUUID} from "@/utils/utils";
import {CURRENT_PRODUCT, IS_GETENC} from "@/utils/build-config";
import {myAxios} from "@/services/myAxios";
import {payWithStripe} from "@/helpers/pay";
import {file} from "paths.macro";
import {eventBus} from "@/main";
import router from "@/router";

export default {
  name: "Payment",
  computed: {
    hasValidAgreement() {
      return this.$store.state.proto.agreement.exist && daysOldWithSign(this.$store.state.proto.agreement.validUntil) > 0;
      // return this.$store.state.current.agreement.id !== -1 && daysOldWithSign(this.$store.state.current.agreement.validdate) > 0;
    },
  },
  methods: {
    isRUBAgreement() {
      return this.$store.state.proto.agreement.currency === 'RUB';
    },
    showModal() {
      this.$refs['payment-modal'].show()
    },
    hideModal() {
      this.$refs['payment-modal'].hide()
    },
    payYoomoney() {
      this.hideModal()
      this.paymentProcess('yoomoney')
    },
    payStripe() {
      this.hideModal()
      this.paymentProcess('stripe')
    },
    payAgreement() {
      eventBus.$emit('agreement-pay')
      this.paymentProcess('agreement')
    },
    paymentProcess(payment_method) {

      // alert(this.$store.state.proto.order.cells)
      const cart = this.$store.state.proto.order.cells
      const cells = this.$store.state.current.cart.cellPrices
      const periodCode = this.$store.state.proto.order.period.period_code
      const period = this.$store.state.proto.order.period.period

      if (cart.length === 0) {
        this.primarOrderCreatingCancel()
        return
      }

      if (payment_method !== 'trial' && payment_method !== 'agreement')
        this.$router.push({name: 'loader'});
      else
        this.is_trial_purchasing_process = true;

      const uuidHash = getUUID(this.$store.state.current.profile.email);

      // let agreementID = this.hasValidAgreement ? this.$store.state.current.agreement.id : -1
      let agreementID = this.hasValidAgreement ? this.$store.state.proto.agreement.id : "-1"
      let deviceID = this.$store.state.proto.order.device_id
      let appType = this.$store.state.proto.order.application_id

      this.$store.dispatch("current/getTotalPrice",
          {products: cart, periodBand: periodCode, agreementID: agreementID, deviceID: deviceID, appType: appType, isManualCalculating: true})
          .then((state) => {

            let totalPrice = payment_method === 'stripe' ? this.$store.state.current.cart.totalPriceByPrimar.toString() : this.$store.state.current.cart.totalPriceByPrimarRUB.toString()
            // alert(totalPrice)
            if (this.hasValidAgreement) {
              // alert(true)
              totalPrice = this.isRUBAgreement() ? this.$store.state.current.cart.totalPriceByPrimarAgreementRUB.toString() : this.$store.state.current.cart.totalPriceByPrimarAgreementUSD.toString();
              // alert(totalPrice)
            }

            const getItems = () => {
              return new Promise(resolve => {
                let items = [];

                for (let i = 0; i < cart.length; i++) {
                  const item = cart[i];
                  const quotate = {
                    items: [item.id],
                    subscriptionType: periodCode,
                    agreementId: agreementID,
                    deviceId: deviceID,
                    applicationTypeId: appType
                  }

                  if (CURRENT_PRODUCT.is_navicom) {
                    let priceByLocation = payment_method === 'yoomoney' ? "350" : "4";

                    items.push({
                      id: item.id,
                      primarOrderTypeId: periodCode,
                      monthCount: period,
                      price: priceByLocation,
                      type: "navicom maps"
                    });

                    if (items.length === cart.length) {
                      resolve(items);
                    }
                  } else {

                    let objIndex = cells.findIndex((obj => obj.id === item.id));
                    if (objIndex === -1)
                      alert("error")

                    let price = cells[objIndex].priceAgreementUsd

                    if (payment_method === 'yoomoney')
                      price = cells[objIndex].priceRub
                    if (payment_method === 'stripe')
                      price = cells[objIndex].priceEuro
                    if (payment_method === 'agreement' && this.isRUBAgreement())
                      price = cells[objIndex].priceAgreementRub

                    items.push({
                      id: item.id,
                      primarOrderTypeId: periodCode,
                      monthCount: period,
                      // price: priceByLocation,
                      price: price,
                      type: item.id.startsWith("102") ? "s102 maps" : "s63 maps"
                    });

                    if (items.length === cart.length) {
                      resolve(items);
                    }

                    // alert("a10")
                    // myAxios.post('/api/v1/orders/primar/quotate/v3', quotate).then((resp) => {
                    //
                    //   let priceByLocation = payment_method === 'yoomoney' ? resp.data.userRub : resp.data.userEuro;
                    //   if (this.hasValidAgreement) {
                    //     priceByLocation = this.isRUBAgreement() ? resp.data.agreementRub : resp.data.agreementUsd;
                    //   }
                    //
                    //   items.push({
                    //     id: item.id,
                    //     primarOrderTypeId: periodCode,
                    //     monthCount: period,
                    //     price: priceByLocation,
                    //     type: item.id.startsWith("102") ? "s102 maps" : "s63 maps"
                    //   });
                    //
                    //   if (items.length === cart.length) {
                    //     resolve(items);
                    //   }
                    // })
                  }
                }
              })
            }
            const userId = this.$store.state.current.profile.user_id;

            const getOrder = async (items) => {
              let order = {};

              // alert(1)

              order.deviceId = this.$store.state.proto.order.device_id
              order.price = totalPrice;
              order.items = items;
              order.comment = uuidHash + " | " + this.$store.state.proto.order.customer
              order.paymentMethod = payment_method;
              order.currency = payment_method === 'stripe' ? 'EUR' : 'RUB'

              if (payment_method === 'agreement') {
                order.currency = this.$store.state.proto.agreement.currency === 'RUB' ? 'RUB' : 'USD'
              }

              order.agreementId = this.$store.state.proto.agreement.id !== -1 ? this.$store.state.proto.agreement.id : "-1";
              order.applicationTypeId = appType,
              order.domain = IS_GETENC ? "getenc-onboard.com" : "mkartweb.com"

              // alert(order.deviceId + " | " + order.price + " | " + order.agreementId + " | " + order.paymentMethod + " | " + order.items)

              console.log("--------------------")
              console.log("--------------------")
              console.log("--------------------")
              console.log("--------------------")
              console.log(order)

              return order
            }

            getItems().then(items => {
              getOrder(items).then(order => {
                console.log('await order', order);
                let purchaseUrl;
                if (userId) {
                  purchaseUrl = '/api/v1/purchase/todeviceid';
                  order.userId = userId;
                } else {
                  purchaseUrl = '/api/v1/purchase/charts/todevice/v2';
                }
                if (order.items[0]) {

                  console.log("aaaaaaaaaaa")
                  console.log("aaaaaaaaaaa")
                  console.log("aaaaaaaaaaa")
                  console.log("aaaaaaaaaaa")
                  console.log(order)

                  myAxios.post(purchaseUrl, order,
                      {
                        headers: {
                          'content-type': 'application/json',
                        }
                      }
                  ).then((resp) => {
                    if (payment_method !== 'agreement')
                      payWithStripe(resp.data, payment_method);
                    else
                      router.push({name:"transactions", params: {qrevent: 'reload-transactions'}})
                  })
                      .catch((error) => {
                        alert('something went wrong [2]', file)
                      })
                      .finally(() => {
                        this.is_trial_purchasing_process = false;
                        console.log('pay: end', file)
                      })
                }
              })
            })
          })
    },
  }
}
</script>

<style scoped>

</style>