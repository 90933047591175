<template>
  <div>
    <!-- Модальное окно PhotoSwipeLightbox -->
    <div class="pswp-gallery" tabindex="-1" role="dialog" aria-hidden="true" id="gallery--no-dynamic-import">
      <div class="container">
        <div class="row row-cols-2 row-cols-md-2 row-cols-lg-3">
          <div v-for="(image, index) in images" :key="index" class="col">
<!--            <a :href="image.src" >123</a>-->

            <a :href="image.src" :data-size="image.width + 'x' + image.height" @click.prevent="openGallery(index)"
               :data-pswp-src="image.thumbnail"
               :data-pswp-width="image.width"
               :data-pswp-height="image.height"
               target="_blank">
              <img :src="image.src" width="100px" class="img-fluid" :alt="image.name">
            </a>

<!--            <a :href="image.src" :data-size="image.originalSize"-->
<!--               :data-pswp-src="image.thumbnail"-->
<!--               :data-pswp-width="image.width"-->
<!--               :data-pswp-height="image.height"-->
<!--               target="#" @click="showImg(image.src)">-->
<!--              <img :src="image.src" width="100px" class="img-fluid" :alt="image.name">-->
<!--            </a>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from "photoswipe/lightbox";

export default {
  props: ['images'],
  computed: {
    viewModes() {
      return [];
    },
    currentViewMode() {
      return 'fit';
    },
    
  },
  methods: {
    //
    // showImg(img) {
    //
    //   var image = new Image();
    //   image.src = img
    //   var _window = window.open('');
    //   _window.document.write(image.outerHTML);
    // },
    //
    openGallery(index) {

      const lightbox = new PhotoSwipeLightbox({
        gallery: '#gallery--no-dynamic-import',
        children: 'a',
        pswpModule: PhotoSwipe,
        initialZoomLevel: 'fit'

      });

      lightbox.init();

    },
  },
};
</script>
<style>
.col{
  padding-bottom: 10px;
}
/*.pswp__img {*/
/*  !*max-width: 100vw ;*!*/
/*  !*max-height: 100vh ;*!*/
/*  !*margin: auto; !* Центрирование изображения *!*!*/
/*  !*max-width: 100em; !* Максимальная ширина 100% *!*!*/
/*  !*max-height: none; !* Убрать максимальную высоту *!*!*/
/*  !*height: auto !important; !* Автоматическая высота *!*!*/
/*}*/
/*!* CSS для режима выравнивания по вертикали *!*/
.pswp__img.vertical-align {
  max-width: none; /* Убрать максимальную ширину */
  max-height: 100%; /* Максимальная высота 100% */
  width: auto !important; /* Автоматическая ширина */
}

/* CSS для режима выравнивания по горизонтали */
.pswp__img.horizontal-align {
  max-width: 100em; /* Максимальная ширина 100% */
  max-height: none; /* Убрать максимальную высоту */
  height: auto !important; /* Автоматическая высота */
}

/* CSS для режима 1:1 */
.pswp__img.one-to-one {
  width: 100% !important; /* Ширина 100% */
  height: 100% !important; /* Высота 100% */
  max-width: none !important; /* Убрать максимальную ширину */
  max-height: none !important; /* Убрать максимальную высоту */
}
</style>