<template>
<div >

  <div v-if="$store.state.proto.order.step === 'creating'">
  <span class="small-gray-card">Total cells:</span> {{$parent.currentCart.length}}
  </div>

  <br v-if="!$parent.is_mobile"><br v-if="!$parent.is_mobile">
  <!--      <b-card v-if="$store.state.proto.order.step !== 'placed'" title="" sub-title="">-->
  <!--        <b-card-text>-->
  <div v-if="$parent.currentCart.length === 0 && !$parent.is_mobile">
    <!--            <a href="#" @click="showPrimarMapBoosterByList()">Click</a> to paste from clipboard, or import <a href="#" @click="">Excel</a> file-->
  </div>

<!--  <div v-else style="max-height: 200px; overflow-y: auto; overflow-x: hidden">-->
  <div v-else >
    <div align="center" v-if="$parent.currentCart.length === 0" class="text-sm-center"><a href="#" @click="showPrimarMapBoosterByList()">Import ENC</a> or click to pickup on the map</div>
    <!--            <div class="col-12 border-right" style="height: 800px; overflow-y: scroll;">-->

    <b-table ref="cellstable" v-if="$store.state.proto.order.step === 'creating'"  selectable thead-class="d-none" select-mode="single" @row-selected="onRowSelected" :items="visibleRows()"
             :fields="visible_fields">

      <template #cell(id)="row">
        {{ row.item.id }}
        <!--                <br> {{ bandName(cellById(row.item.id).usageBand) }}-->
      </template>

      <!--              <template #cell(name)="row">-->
      <!--                {{ row.item.name.toString().substring(0, 8) + "..." }}-->
      <!--              </template>-->

      <template #cell(band)="row">
        <span class="small-gray-card">{{ bandName(cellById(row.item.id).usageBand) }}</span>
      </template>


      <template #cell(price)="row">
        {{ getCellPrice(row.item.id) }}
      </template>

      <template #cell(selected)="row">
        <!--                <a href="#" @click="toggleCellInclusion(row.item.id)">-->
        <!--                  <b-icon v-if="row.item.disabled" icon="toggle-off" variant="dark"></b-icon>-->
        <!--                  <b-icon v-else icon="toggle-on" variant="success"></b-icon>-->
        <!--                </a>-->
        <b-checkbox switch :checked="!row.item.disabled" @change=toggleCellInclusion(row.item.id)  ></b-checkbox>
      </template>

    </b-table>
  </div>
</div>
</template>

<script>
import {eventBus} from "@/main";
import * as utils from "@/utils/utils";

export default {
  name: "CellsTable",
  // props: ['is_mobile','currentCart', 'map'],
  data(){
    return{

    }
  },
  mounted() {
    eventBus.$on('make-row-active', (id) => {
      this.onCellClicked(id)
    })
  },
  methods:{
    showPrimarMapBoosterByList() {
      // alert(1)
      this.$parent.openCellsList = true;
      // this.$parent.showPrimarMapBoosterByList();
      // showPrimarMapBoosterByList
    },
    getCellPrice(id){
      return this.$parent.getCellPrice(id);
    },
    toggleCellInclusion(id) {
      console.log()
      this.$store.dispatch('current/updateCellStaus', id).then( status => {
        console.log(status)
        if (status){
          this.$parent.map.unselectCell(id);
          this.$parent.countTotalPrice()
        }
        else {
          const m = this.$parent.map.match(id);
          const scope = m.scope;
          const field = m.field;
          this.$parent.map.replaceCell(scope, field, id)
          this.$parent.countTotalPrice()
        }
      })
    },
    bandName(code) {
      return utils.getBandName(code);
    },
    cellById(id) {
      return this.$store.getters.cellById(id)
    },
    onRowSelected(items) {
      if (items.length > 0) {
        // alert(items[0].id + '|' + this.summary)
        console.log(items, '8888')
        console.log(items[0], '8888')
        this.showCellOnMap(items[0].id, this.summary)
        this.highlightCell(items[0].id)
      }
    },
    visibleRows() {
      console.log(this.$parent.currentCart.slice().reverse(), 'rows')
      return this.$parent.currentCart.slice().reverse()
    },
    onCellClicked(id) {
      if (this.$refs.cellstable) {
        let x = this.$refs.cellstable.computedItems;
        let row = this.$refs.cellstable.computedItems.findIndex(x => x.id === id);
        console.log(id, row, this.items);
        this.$refs.cellstable.selectRow(row);
        this.scrollToRow(row);
      }
    },
    scrollToRow(index) {
      const tbody = this.$refs.cellstable.$el.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[index]
      row.scrollIntoView()
    },
    highlightCell(id) {
      // alert("hc " + id)
      eventBus.$emit('make-cell-active', id)
    },
    showCellOnMap(id, items) {

      eventBus.$emit('pane2cell', {id: id, items: items, color: 'green'})

      const url = 'https://win1hetz.bgeo.fi:5005/' + id + '/{z}/{x}/{y}'

      // this.map.setSpecialTiles(url);
      eventBus.$emit('show-cell-on-map', url)
    },
  },
  computed: {
    visible_fields() {
      return [
        {key: 'selected', label: 'Selected', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'id', label: 'ID', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'name', label: 'Name', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'band', label: 'Band', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'price', label: 'Price', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
  },
}
</script>

<style scoped>

</style>