import store from "@/store";

export function updateRoute(tempNum, data){
    let routesData = localStorage.getItem('route') ? JSON.parse(localStorage.getItem('route')) : {}
    routesData[tempNum] = data;
    localStorage.setItem('route', JSON.stringify(routesData));
}

// export function updateZones(tempNum, data){
//     let routesData = localStorage.getItem('route') ? JSON.parse(localStorage.getItem('route')) : {}
//     routesData[tempNum] = data;
//     localStorage.setItem('route', JSON.stringify(routesData));
// }