<template>
  <b-sidebar width="600px" id="delivery-sidebar" shadow>
    <div class="px-3 py-2">
      <b-badge class="mb-2" variant="light">DELIVERY</b-badge>
      <b-card v-if="data">
        <b-row class="mb-2">
          <b-col class="listsmall">
            <b-button variant="info" block @click="downloadENC($parent.current.data.displayId)">Download the order: {{$parent.current.data.displayId}}</b-button><br>
            <p>After clicking the button, the order will begin downloading. Please note that downloading may take a few minutes.</p>

<!--            <b-table ref="deliverytable" striped :items="visible_rows()" >-->
<!--                <template #cell(link)="row">-->
<!--&lt;!&ndash;                  <b-link target="_blank" href="https://primar.ecc.no/primar/vexport/25ebafa3-f683-4db5-b7e2-ae29d045428a">https://primar.ecc.no/primar/vexport/25ebafa3-f683-4db5-b7e2-ae29d045428a</b-link>&ndash;&gt;-->
<!--                </template>-->
<!--          </b-table>-->
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
import {eventBus} from "@/main";
import {myAxios} from "@/services/myAxios";
import {Axios} from "axios";
import store from "@/store";

export default {
  name: "Delivery",
  props: ["data"],
  mounted() {
    eventBus.$on('refresh-deliverytable-table', () => {
      setTimeout(() => {
        this.$refs.deliverytable.refresh();
      }, 1000);
    })
  },
  computed: {
    visible_fields() {
      return [
        {key: 'date', label: 'Date', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'text', label: 'Link', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    }
  },
  methods: {
    downloadENC(id) {

      window.open('https://mkart-api.bgeo.fi/mkart/api/Order/download-charts?orderId=' + id, '_blank').focus();
      // window.open('https://mkart-api.bgeo.fi/mkart/api/Order/download-charts?orderId=18678', '_blank').focus();
      // window.open('https://mkart-api.bgeo.fi/mkart/api/Order/download-charts?orderId=19880', '_blank').focus();

      // let a = document.createElement("a");
      // let content = 'https://mkart-api.bgeo.fi/mkart/api/Order/download-charts?orderId=18678'
      // let jsonString = [JSON.stringify(content, null, 2)];
      // let file = new Blob([jsonString], {type: 'text/plain'});
      // a.href = URL.createObjectURL(file);
      // a.download = "_dump_" + name + ".zip";
      // a.click();


      // const FileDownload = require('js-file-download');


      // myAxios.get('https://mkart-api.bgeo.fi/mkart/api/Order/download-charts?orderId=19880', {
      //   headers: {
      //     "content-type": "application/zip",
      //   }
      // }).then((resp) => {
      //   console.log("AAAAAAAAAAAAA1")
      //   FileDownload(resp.data, 'report.zip');
      // }).catch((error) => {
      //   console.log("AAAAAAAAAAAAA2")
      // })

      // myAxios({
      //   url: 'https://mkart-api.bgeo.fi/mkart/api/Order/download-charts?orderId=19880',
      //   method: 'GET',
      //   responseType: 'blob', // Important
      // }).then((response) => {
      //   FileDownload(response.data, 'report.zip');
      // });

      // FileDownload('https://mkart-api.bgeo.fi/mkart/api/Order/download-charts?orderId=19880', 'filename.zip');

      // let url = 'https://mkart-api.bgeo.fi/mkart/api/Order/download-charts?orderId=' + id
      // myAxios.get(url).then(resp => {
      //   console.log("aaa1")
      // }).catch(error => {
      //   console.log("aaa2", error)
      // })
    },
    visible_rows() {
      return [
        {date: '', to: "", link: 'link'},
      ]
    }
  }
}
</script>

<style scoped>

</style>