<template>
  <div class="mt-2">
    <b-table striped small :stacked="is_mobile" :items=visible_rows :fields="visible_fields"></b-table>
    <b-button size="sm" variant="info" @click="agreement()">Agreements</b-button>
<!--    <b-button size="sm" class="ml-2" variant="success">Add</b-button>-->
  </div>
</template>

<script>
export default {
  name: "Client",
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    is_mobile() {
      return this.windowWidth <= 760
    },
    visible_rows() {
      return this.$store.state.distributor.agreementUsers
    },
    visible_fields() {
      return [
        {key: 'email', label: 'Email', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'shipName', label: 'Ship', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'deviceId', label: 'Device ID', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  methods: {
    agreement() {
      this.$parent.showClients = false
    }
  }
}
</script>

<style scoped>

</style>