<template>
  <div>
      <search></search>
      <b-container fluid>
        <b-row>
          <b-col order=1 order-lg=2 lg="8">
            <my-map :map=map :height=map_size></my-map>
          </b-col>
          <b-col order=2 order-lg=1 lg="4">
            <ship-logs></ship-logs>
          </b-col>
        </b-row>
<!--        <b-row>-->
<!--          <b-col>-->
<!--&lt;!&ndash;            <my-map :map=map :height=map_size></my-map>&ndash;&gt;-->
<!--          </b-col>-->
<!--        </b-row>-->
      </b-container>
  </div>
</template>

<script>
import ShipMap from "@/views/maps/ShipMap.vue";
import ShipLogs from "@/views/maps/ShipLogs.vue";
import {ShipLeaf} from "@/my-leaf/ship-leaf";
import Search from "@/views/routes/Search.vue";

export default {
  components: {MyMap: ShipMap, ShipLogs, Search},
  data() {
    return {
      windowWidth: window.innerWidth,
      map: new ShipLeaf(),
      mapHeight: window.innerHeight / 1.2,
      mapHeightMobile: window.innerHeight / 1.8,
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    if (this.$store.state.fickle.qr.shipLogs.length === 0) {
      this.$router.push({name: 'ships'})
    }
  },
  computed: {
    is_mobile() {
      return this.windowWidth <= 760
    },
    map_size() {
      if (this.is_mobile)
        return window.innerHeight / 1.8
      else
        return window.innerHeight / 1.2
    },
  },
  methods: {
  },
}
</script>
