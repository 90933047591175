import {eventBus} from "@/main";

export function prepareError(errorCode, points) {
    let text = 'Error: (' + errorCode + ') '
    switch (errorCode) {
        case 3: text = 'Start point in a wrong area'
                break;
        case 4: text = 'Finish point in a wrong area'
                break;
        default: text = 'Can\'t compute the route'
    }
    text = 'Error: (' + errorCode + ') ' + text + points

    eventBus.$emit('show-route-error', text)
}
