<template>
  <div>
    <!--    <b-modal id="modal-1" title="Confirm" v-model="openDialog" ok-title="Remove" @ok="removeRowHandler">-->
    <!--      <p class="my-4">Are you sure you want to remove the selected rows?</p>-->
    <!--    </b-modal>-->

    <div>

<!--                <pre>-->
<!--            {{currentRowIndex}}-->
<!--          </pre>-->

      <div v-if="($store.state.routes.route.length > 0)">
        <b-badge>Route start time: {{routeTime()}}</b-badge>
      </div>

      <b-table selectable sticky-header select-mode="single" ref="wptable" id="routes-table" style="max-height: 400px;"
               @row-selected="onRowSelected" @row-clicked="onRowClicked" :items="items1" :fields="visible_fields">
        <template #cell(name)="data">
          <!--          <span class="smt4">WP{{data.index}}</span>-->
          <span v-if="isDanger(data.index)" class="smt14">WP{{ data.index }}</span>
          <span v-else class="smt4">WP{{ data.index }}</span>

        </template>

        <!--        <template #cell(lat)="data">-->
        <!--          <b-form-input v-if="items[data.index].isEdit && selectedCell === 'lat'" type="text" v-model="items[data.index].lat"></b-form-input>-->
        <!--          <span v-else @click="editCellHandler(data, 'lat')">{{data.value}}</span>-->
        <!--        </template>-->

        <template #cell(lat)="row">
          {{ row.item.lat.toFixed(6) }}, {{ row.item.lon.toFixed(6) }}
        </template>

        <!--        <template #cell(speed)="data">-->
        <!--          {{$store.state.routes.route_settings.speed}}-->
        <!--        </template>        -->

        <template #cell(speed)="data">
          <b-form-input v-if="items1[data.index].isEdit && selectedCell === 'speed'" type="text" v-model="items1[data.index].speed"></b-form-input>
          <span v-else @click="editCellHandler(data, 'speed')">{{ items1[data.index].speed }}</span>
        </template>

        <template #cell(length)="data">
          <span v-if="$store.state.routes.info.length > 0 && $store.state.routes.info[data.index]">

            <div v-if="$store.state.routes.info[data.index].fromPreviousMeters < 1000">
              +{{ ($store.state.routes.info[data.index].fromPreviousMeters).toFixed(1) }}m,
            </div>
            <div v-else>
              +{{ ($store.state.routes.info[data.index].fromPreviousMeters * 0.000539957).toFixed(1) }}nm,
            </div>
            {{ ($store.state.routes.info[data.index].fromStartMeters * 0.000539957).toFixed(1) }}nm
          </span>
        </template>

        <template #cell(eta)="data">
          <span v-if="$store.state.routes.info.length > 0 && $store.state.routes.info[data.index]">

            <span v-if="$store.state.routes.route_settings.date === '' || $store.state.routes.route_settings.time === ''">
              {{ new Date(($store.state.routes.info[data.index].fromStartSeconds ?? 0) * 1000).toISOString().substring(11, 16) }}
            </span>
            <span v-else>
              {{datem($store.state.routes.info[data.index].fromStartSeconds)}}
            </span>


<!--            {{ new Date(($store.state.routes.info[data.index].fromStartSeconds ?? 0) * 1000).toISOString().substring(11, 16) }}-->
          </span>
          <!--          {{$store.state.routes.info[data.index + 1].fromStartSeconds}}-->
        </template>
        <!--        <template #cell(image)="row">-->
        <!--          <b-button v-if="row.item.images" class="unstyled-button" size="sm" title="Images" @click="showImage(row.item)">-->
        <!--            <b-icon variant="dark" icon="card-image" aria-hidden="true"></b-icon>-->
        <!--          </b-button>-->
        <!--        </template>-->
        <!--        <template #cell(audio)="row">-->
        <!--          <b-button v-if="row.item.audio" class="unstyled-button" size="sm" title="Images" @click="playAudio(row.item)">-->
        <!--            <b-icon variant="dark" icon="mic" aria-hidden="true"></b-icon>-->
        <!--          </b-button>-->
        <!--        </template>-->
        <!--        <template #cell(messages)="row">-->
        <!--          <b-button v-if="row.item.info.message" class="unstyled-button" size="sm" title="Images" @click="showMessages(row.item)">-->
        <!--            <b-icon variant="dark" icon="file-earmark-text" aria-hidden="true"></b-icon>-->
        <!--          </b-button>-->
        <!--        </template>-->
        <!--        <template #cell(time)="data">-->
        <!--          {{ getDate(data.value) }}-->
        <!--        </template>-->
      </b-table>


      <!--      <b-table @row-clicked="onRowClicked" ref="wptable" small sort-icon-left hover selectable select-mode="single" @row-selected="onRowSelected" :items="tableItems" :fields="fields" id="routes-table">-->
      <!--        <template v-for="(field, index) in fields" #[`cell(${field.key})`]="data">-->
      <!--          <b-form-datepicker v-if="field.type === 'date' && tableItems.at([data.index]).isEdit" :key="index"-->
      <!--                             :type="field.type" :value="tableItems.at([data.index])[field.key]"-->
      <!--                             @input="(value) => inputHandler(value, data.index, field.key)"></b-form-datepicker>-->
      <!--          <b-form-select v-else-if="field.type === 'select' && tableItems.at([data.index]).isEdit" :key="index"-->
      <!--                         :value="tableItems.at([data.index])[field.key]"-->
      <!--                         @input="(value) => inputHandler(value, data.index, field.key)"-->
      <!--                         :options="field.options"></b-form-select>-->

      <!--          <div :key="index" v-else-if="field.type === 'edit'">-->
      <!--            <b-button class="border-0" size="sm" variant="outline-info" @click="editRowHandler(data)">-->
      <!--              <b-icon v-if="!tableItems.at([data.index]).isEdit" icon="pencil" font-scale="1"></b-icon>-->
      <!--              <b-icon v-else icon="check" font-scale="1"></b-icon>-->
      <!--            </b-button>-->
      <!--            <b-button class="border-0" size="sm" variant="outline-danger"-->
      <!--                      @click="openDialog = true; currentIndex = data.index">-->
      <!--              <b-icon icon="x" font-scale="1.5"></b-icon>-->
      <!--            </b-button>-->
      <!--          </div>-->
      <!--          <b-form-input v-else-if="field.type && tableItems.at([data.index]).isEdit" :key="index" :type="field.type"-->
      <!--                        :value="tableItems.at([data.index])[field.key]"-->
      <!--                        @blur="(e) => inputHandler(e.target.value, data.index, field.key)"></b-form-input>-->

      <!--          <span :key="index" v-if="field.key === 'lat'">{{ data.value.toFixed(6) }}</span>-->
      <!--          <span :key="index" v-if="field.key === 'lon'">{{ data.value.toFixed(6) }}</span>-->
      <!--          <span :key="index" v-if="field.key === 'name'">WP{{ data.index }}</span>-->
      <!--          <span :key="index" v-if="field.key === 'radius'">{{ data.value }}</span>-->
      <!--          <span :key="index" v-if="field.key === 'xteLeft'">{{ data.value }}</span>-->
      <!--          <span :key="index" v-if="field.key === 'xteRight'">{{ data.value }}</span>-->
      <!--        </template>-->
      <!--      </b-table>-->
    </div>
  </div>
</template>

<script>
import {eventBus} from "@/main";
// import moment from 'moment';
import {PythonArray} from "@/helpers/pythonArray"
import {indexOf} from "leaflet/src/core/Util";
import * as dayjs from 'dayjs/dayjs.min'

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "RouteTable",
  props: {
    fields: Array
  },
  data() {
    return {
      prevRowIndex: -1,
      currentRowIndex: -1,
      centerRowIndex: -1,
      focusWPIndex: -1,
      // rowsToSelect: PythonArray.from([]),
      isShowSearch: false,
      searchFilter: null,
      // openDialog: false,
      // currentIndex: -1,
      selectedCell: null
    }
  },

  computed: {
    getSpeed() {
      return document.getElementById('sb-inline-speed').value
    },
    getDate() {


      return document.getElementById('sb-inline-date').value
    },
    getTime() {
      return document.getElementById('sb-inline-time').value
    },
    visible_fields() {
      return [
        {key: 'name', label: 'Name', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'lat', label: 'Coordinates', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'speed', label: 'Speed (knots)', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'eta', label: 'ETA', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'length', label: 'Length (leg, full)', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'edit', label: ''}
      ]
    },
    items1() {
      return this.$store.state.routes.route
      // return this.makeTableItems(this.$store.state.routes.route)
    },
  },
  watch: {
    // tableItems() {
    //   setTimeout(() => {
    //     const tbody = this.$refs.wptable.$el.querySelector('tbody')
    //     // const rows = tbody.querySelectorAll('tr')
    //     // this.rowsToSelect = PythonArray.from(rows)
    //     // console.log(this.rowsToSelect)
    //   }, 500)
    //
    // }
  },
  mounted() {

    // this.items = this.items1.map(item => ({...item, isEdit: false}));

    eventBus.$off('select-routetable-row')
    eventBus.$off('set-center-index')
    eventBus.$off('focus-center-index')
    eventBus.$off('move-current')
    eventBus.$off('move-next')
    eventBus.$off('move-prev')
    eventBus.$off('clear-current-wp-index')

    eventBus.$on('clear-current-wp-index', () => {
      this.currentRowIndex = -1
      eventBus.$emit('show-wp-index', this.currentRowIndex)
      eventBus.$emit('clear-current-wp-index-weather')
      eventBus.$emit('clear-current-wp-index-zones')
    })

    eventBus.$on('move-current', () => {
      // alert(1)
      eventBus.$emit('move-current-weather')
      eventBus.$emit('move-current-zones')
      if (this.prevRowIndex !== -1) {
        this.currentRowIndex = this.prevRowIndex
        this.selectRow(this.currentRowIndex)
      }
    })

    eventBus.$on('move-next', () => {
      // alert(2)
      console.log("qqq", this.currentRowIndex)

      eventBus.$emit('move-next-weather')
      eventBus.$emit('move-next-zones')
      if (this.currentRowIndex !== -1) {
        this.currentRowIndex = this.currentRowIndex === this.$store.state.routes.route.length - 1 ? 0 : this.currentRowIndex + 1
        this.prevRowIndex = this.currentRowIndex
        this.selectRow(this.currentRowIndex)
      } else {
        this.selectRow(this.focusWPIndex)
      }

      // this.map.closePopup();

      eventBus.$emit('switch-popup', this.currentRowIndex)
      eventBus.$emit('show-dangers-in-wp', this.currentRowIndex)

      // if (this.centerRowIndex === -1) {
      //   this.currentRowIndex = this.currentRowIndex === this.$store.state.routes.route.length - 1 ? 0 : this.currentRowIndex + 1
      //   this.selectRow(this.currentRowIndex)
      // } else {
      //   this.currentRowIndex = this.centerRowIndex === this.$store.state.routes.route.length - 1 ? 0 : this.centerRowIndex + 1
      //   this.selectRow(this.currentRowIndex)
      // }
    })
    eventBus.$on('move-prev', () => {
      // alert(3)
      eventBus.$emit('move-prev-weather')
      eventBus.$emit('move-prev-zones')
      // console.log("aaa", this.currentRowIndex, this.focusWPIndex)
      console.log(this.currentRowIndex)
      if (this.currentRowIndex !== -1) {
        this.currentRowIndex = this.currentRowIndex === 0 ? this.$store.state.routes.route.length - 1 : this.currentRowIndex - 1
        this.prevRowIndex = this.currentRowIndex
        this.selectRow(this.currentRowIndex)
      } else {
        this.selectRow(this.focusWPIndex)
      }

      // this.map.closePopup();

      eventBus.$emit('switch-popup', this.currentRowIndex)
      eventBus.$emit('show-dangers-in-wp', this.currentRowIndex)

      // if (this.centerRowIndex === -1) {
      //   this.currentRowIndex = this.currentRowIndex === 0 ? this.$store.state.routes.route.length - 1 : this.currentRowIndex - 1
      //   this.selectRow(this.currentRowIndex)
      // } else {
      //   this.currentRowIndex = this.centerRowIndex === 0 ? this.$store.state.routes.route.length - 1 : this.centerRowIndex - 1
      //   this.selectRow(this.currentRowIndex)
      // }
    })

    eventBus.$on('set-center-index', (index) => {
      this.centerRowIndex = index
    })

    eventBus.$on('set-focus-index', (index) => {
      this.focusWPIndex = index
      eventBus.$emit('set-focus-index-weather', index)
      eventBus.$emit('set-focus-index-zones', index)
    })

    eventBus.$on('select-routetable-row', (id) => {
      eventBus.$emit('select-routetable-row-weather', id)
      eventBus.$emit('select-routetable-row-zones', id)
      console.log("aaa2", this.aaa)
      console.log("aaa2", this.$parent.aaa)
      console.log("aaa4", this.$parent.$parent.aaa)
      console.log("aaa4", this.$parent.$parent.$parent.aaa)
      // alert(id)
      // this.selectRow(id)
      // alert(2)
      this.currentRowIndex = id
      this.$refs.wptable.selectRow(id);
      const tbody = this.$refs.wptable.$el.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[id]
      // row.scrollIntoView()
      row.scrollIntoView({block: 'center'})
      eventBus.$emit('show-wp-index', id)
    })

  },
  methods: {
    indexOf,
    editCellHandler(data, name) {
      // this.items = this.items1.map(item => ({...item, isEdit: false}));
      this.items1[data.index].isEdit = true;
      this.selectedCell = name
    },
    onRowClicked(row, index) {
      eventBus.$emit('close-popup')
      this.items1.forEach((item, i) => {
        item.isEdit = false
      })
      // alert('bbb')
      console.log(row)
      console.log(index)
      this.currentRowIndex = index
      // eventBus.$emit('show-wp-index', index)
      eventBus.$emit('click-wp', index)

      eventBus.$emit('show-dangers-in-wp', index)

    },
    routeTime() {
      let date = this.$store.state.routes.route_settings.date
      let time = this.$store.state.routes.route_settings.time
      // let datetime = moment().day(date).hour(time)
      // let datetime = moment(date + " " + time).format("DD MMM HH:mm")
      let datetime = dayjs(date + " " + time).format("DD MMM HH:mm")
      if (datetime === "Invalid date")
        datetime = "N/A"
      return datetime
    },
    datem(seconds) {
      // let a = "12:01:00"
      // let b = "10"

      // let i1 = "23:01:00"
      // let i2 = "2024-02-14 12:14"

      let date = this.$store.state.routes.route_settings.date
      let time = this.$store.state.routes.route_settings.time
      console.log("ggg1", date)
      console.log("ggg1", time)


      // let datetime = moment(date + " " + time, "YYYY-MM-DD HH:mm")
      let datetime = dayjs(date + " " + time)

      let datetimenew = datetime.add(seconds, "seconds")
      let shortdate = datetimenew.format("DD/MM HH:mm")

      return shortdate

      // let am = moment({ hour:12, minute:1 });
      // let bm = am.add(10, 'minutes')

      // return bm.toDate()

      // return moment("20111031", "YYYYMMDD").fromNow();
    },
    selectRow(index) {
      // alert(index)
      // alert('aaa')
      this.$refs.wptable.unselectRow(index);
      setTimeout( () => {
        this.$refs.wptable.selectRow(index);
      }, 100)
//      this.$refs.wptable.selectRow(index);
      // this.scrollToRow(index);
      this.currentRowIndex = index

      // eventBus.$emit('show-wp-index', index)
      eventBus.$emit('click-wp', index)

      // this.rowsToSelect[index].click()
      // console.log(index, this.rowsToSelect[index], this.currentRowIndex, 'wpin')
    },
    isDanger(index) {

      let dangers = this.$store.state.routes.dangers.filter(function (e) {
        return e.isDanger === true && e.legIndex === index
      });

      return dangers.length > 0

      // $store.state.routes.dangers[data.index]
      // return true
    },

    // commitChanges() {
    //   this.$store.commit('routes/setRoutePoints', this.tableItems.route);
    //   eventBus.$emit('route-changed-from-table', this.tableItems);
    // },
    // makeTableItems(items) {
    //   return items.map(item => ({...item, isEdit: false}));
    // },
    onRowSelected(items) {
      console.log(items)
      eventBus.$emit('wp-selected', items[0])
    },
    // editRowHandler(data) {
    //   this.tableItems[data.index].isEdit = !this.tableItems[data.index].isEdit;
    //   if (this.tableItems[data.index].isEdit === false) this.commitChanges();
    // },
    // inputHandler(value, index, key) {
    //   this.tableItems[index][key] = value;
    //   this.$set(this.tableItems, index, this.tableItems[index]);
    //   this.$emit("input", this.tableItems);
    // },
    // addRowHandler() {
    //   const newRow = this.fields.reduce((a, c) => ({...a, [c.key]: null}), {})
    //   newRow.isEdit = true;
    //   this.tableItems.unshift(newRow);
    //   this.$emit('input', this.tableItems);
    // },
    // removeRowHandler() {
    //   this.tableItems = this.tableItems.filter((item, i) => i !== this.currentIndex);
    //   this.$emit('input', this.tableItems);
    //   this.commitChanges();
    // }
  }
}
</script>

<style scoped>

</style>