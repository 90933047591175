<template>
  <b-container v-if="$store.state.fickle.offline.userLoading" fluid>
    <div align="center" class="text-centercenter text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong>&nbsp;Loading...</strong>
    </div>
  </b-container>
  <b-container v-else fluid>
    <div v-if="$store.state.distributor.agreements.length === 0" class="text-sm-centercenter text-danger my-2" align="center">
      <b-spinner small class="align-middle"></b-spinner>
      <strong>&nbsp;{{$store.state.fickle.requests.current_request}}</strong>
    </div>

    <b-row v-if="!showClients && !isLoading">
      <b-col>
        <agreement v-if="user_agreements.length > 0"></agreement>
          <b-alert :show="user_agreements.length === 0 && $store.state.distributor.agreements.length > 0">
            NO AGREEMENTS
          </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="showClients && !isLoading">
      <b-col>
        <client></client>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import store from "@/store";
import Agreement from "@/views/agreement/Agreement.vue";
import Client from "@/views/agreement/Client.vue";
import {eventBus} from "@/main";
import {checkInternetLoop, loadData, loadENCData, loadUserData} from "@/helpers/offline";

export default {
  components: {Agreement, Client},
  data() {
    return {
      interval: null,
      showClients: false,
      isLoading: false,
      agreement_owner: '',
      user_agreements: [],
      agreement_clients:[]
    }
  },
  mounted() {

    this.interval = setInterval(checkInternetLoop, 5000)

    console.log("off mount")
    if (!this.$store.state.fickle.offline.userDataReady) {
      console.log("offline start")
      loadUserData().then( resp => {
            console.log("off3")
            this.prepareAgreements()
          }
      )
    } else {
      this.prepareAgreements()
    }

    // alert("aaa")


    eventBus.$on('return-to-agreement', () => {
      this.showClients = false
    })



    //AAA TODO check
    // this.$store.dispatch("distributor/getAgreements").then(() => {
    //   let all_agreements = this.$store.state.distributor.agreements
    //   let index = 0
    //   let agreements_id = []
    //   let userId = this.$store.state.auth.user.displayId ? this.$store.state.auth.user.displayId : 93384 // TODO
    //   all_agreements.forEach(agreement => {
    //     if (agreement.distributor.mainUserId === userId) {
    //
    //       agreements_id.push(agreement.id)
    //
    //       if (this.user_agreements.filter(e => e.id === agreement.id).length === 0) {
    //         this.user_agreements.push({
    //           index: index++,
    //           id: agreement.id,
    //           signed: new Date(agreement.signedAt).toLocaleDateString(),
    //           valid: new Date(agreement.validTill).toLocaleDateString(),
    //           currency: agreement.currency,
    //           title: agreement.name,
    //         })
    //         this.agreement_owner = agreement.distributor.title
    //       }
    //     }
        // console.log(distributor.distributor.id)
        // console.log(distributor.distributor.mainUserId)
        // console.log(this.$store.state.auth.user.displayId)
      // })
      // console.log(this.user_agreements)
      // console.log(agreements_id)
    // })
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    prepareAgreements() {
      console.log("off prepare")
      console.log("off", this.$store.state.distributor.agreements)
      let all_agreements = this.$store.state.distributor.agreements
      console.log("off", all_agreements)
      let index = 0
      let agreements_id = []
      let userId = this.$store.state.auth.user.displayId ? this.$store.state.auth.user.displayId : 93384 // TODO
      all_agreements.forEach(agreement => {
        if (agreement.distributor.mainUserId === userId) {

          agreements_id.push(agreement.id)

          if (this.user_agreements.filter(e => e.id === agreement.id).length === 0) {
            this.user_agreements.push({
              index: index++,
              id: agreement.id,
              signed: new Date(agreement.signedAt).toLocaleDateString(),
              valid: new Date(agreement.validTill).toLocaleDateString(),
              currency: agreement.currency,
              title: agreement.name,
            })
            this.agreement_owner = agreement.distributor.title
          }
        }
        // console.log(distributor.distributor.id)
        // console.log(distributor.distributor.mainUserId)
        // console.log(this.$store.state.auth.user.displayId)
      })
    }
  }
}
</script>

<style scoped>
</style>