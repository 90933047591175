<template>
  <b-sidebar width="600px" id="updates-sidebar" shadow>
    <div class="px-3 py-2">
      <b-badge class="mb-2" variant="light">ORDER UPDATES</b-badge>
      <b-card v-if="data">
          <b-row class="mb-2">
            <b-col class="listsmall">
              <b-table ref="deliverytable" striped :items="visible_rows()" :fields="visible_fields">
                <template #cell(status)="data">
                  <h6><b-badge :variant=getStatus(data.value).style>{{ getStatus(data.value).name }}</b-badge></h6>
                </template>
                <template #cell(timestamp)="data">
                  {{ utils.formatDate(data.value).datetime }}
                </template>
              </b-table>
            </b-col>
          </b-row>
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
import {eventBus} from "@/main";
import * as utils from "../../../utils/utils";

export default {
  name: "Updates",
  props: ["data"],
  mounted() {
    eventBus.$on('refresh-deliverytable-table', () => {
      setTimeout(() => {
        this.$refs.deliverytable.refresh();
      }, 1000);
    })
  },
  computed: {
    utils() {
      return utils
    },
    visible_fields() {
      return [
        {key: 'status', label: 'Status', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'timestamp', label: 'Date', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'userEmail', label: 'User', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    }
  },
  methods: {
    visible_rows() {
      return this.data.statusChangeLog

      // return [
      //   {date: '2023-09-13', from: "sab@probki.net", status: 'request', charts: ''},
      //   {date: '2023-09-13', from: "aga@probki.net", status: 'request', charts: 'changed'},
      //   {date: '2023-09-14', from: "aga@probki.net", status: 'order', charts: ''},
      //   {date: '2023-09-13', from: "aga@probki.net", status: 'to pay', charts: ''},
      // ]
    },
    getStatus(status) {
      let val = {}
      switch (status) {
        case 'pre_order':
          val = {style: "info", name: "request"}
          break
        case 'owner_approved':
          val = {style: "warning", name: "to pay"}
          break
        case 'new':
          val = {style: "warning", name: "to pay"}
          break
        case 'paid':
        case 'ready_to_publish':
        case 'awaiting_charts"':
        case 'adding_charts"':
          val = {style: "secondary", name: "processing"}
          break
        case 'complete':
          val = {style: "success", name: "complete"}
          break
        case 'error':
          val = {style: "danger", name: "error"}
          break
        case 'cancelled_by_distirbutor':
          val = {style: "danger", name: "cancelled"}
          break
        default:
          val = {style: "light", name: status}
      }

      return val
    }
  }
}
</script>

<style scoped>

</style>