<template>
  <b-sidebar width="600px" id="items-sidebar" shadow>
<!--    {{data?.device.shipName}}<br>-->
<!--    {{data?.paymentMethod.paymentMethod}}<br>-->
<!--    {{data?.paymentMethod.agreementInfo.title}}<br>-->
<!--    {{data?.paymentMethod.agreementInfo.agreementId}}<br>-->
<!--    {{data?.createdAt}}<br>-->
<!--    {{data?.orderId}}<br>-->
<!--    {{$store.state.fickle.order}}-->
<!--    {{aaa}}-->
    <div class="px-3 py-2">
      <b-badge class="mb-2" variant="light">ITEMS</b-badge>
      <b-card v-if="data">

          <b-row class="mb-2">
            <b-col sm="3" class="text-left"><b>Ship name:</b></b-col>
            <b-col class="listsmall">{{data?.device.shipName}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-left"><b>Payment by:</b></b-col>
            <b-col class="listsmall">{{data?.paymentMethod.paymentMethod}}</b-col>
          </b-row>
          <b-row v-if="data?.paymentMethod.paymentMethod === 'agreement'" class="mb-2">
            <b-col sm="3" class="text-left"><b>Agreement:</b></b-col>
            <b-col class="listsmall">{{data?.paymentMethod.agreementInfo.title}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-left"><b>Created:</b></b-col>
            <b-col class="listsmall">{{utils.formatDate(data?.createdAt).datetime}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-left"><b>Paid:</b></b-col>
            <b-col class="listsmall">{{$store.state.fickle.order.isPaid ? "Yes" : "No"}}</b-col>
          </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-left"><b>Cells:</b></b-col>
          <b-col class="listsmall">{{$store.state.fickle.order.items.length}}
            &nbsp;(<a href='#' v-on:click='copy_to_clipboard()'>copy ENC IDs to clipboard</a>)
          </b-col>
        </b-row>
          <b-row class="mb-2">
          <b-col class="listsmall">
            <b-table striped :items="visible_rows()" :fields="visible_fields">
                <template #cell(validTill)="row">
                  {{ utils.formatDate(utils.nextDate(data?.createdAt, row.item.monthCount)).date }}
                </template>
          </b-table>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
import {eventBus} from "@/main";
import * as utils from "../../../utils/utils";
import store from "@/store";

export default {
  name: "Items",
  props: ["data"],
  // data() {
  //   return {
  //     aaa: null
  //   }
  // },
  mounted() {
    // eventBus.$on('refresh-deliverytable-table', () => {
    //   setTimeout(() => {
    //     this.$refs.deliverytable.refresh();
    //   }, 1000);
    // })

    // this.$store.dispatch('fickle/getOrder', {
    //   id: this.info.orderId
    // }).then((value) => {
    //   this.aaa = value
    // })
  },
  computed: {
    utils() {
      return utils
    },
    visible_fields() {
      return [
        {key: 'id', label: 'ID', sortable: false, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'type', label: 'Type', sortable: false, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'monthCount', label: 'Period', sortable: false, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'validTill', label: 'Valid till', sortable: false, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    }
  },
  methods: {
    visible_rows() {
      return this.$store.state.fickle.order.items
    },
    maps_list() {
      let items = ''
      this.$store.state.fickle.order.items.forEach(item => {
        items += item.id + "\n"
      })
      return items
    },
    copy_to_clipboard() {
      navigator.clipboard.writeText(this.maps_list())
    }

  }
}
</script>

<style scoped>

</style>