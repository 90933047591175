import api from '@/dbase/idb'

const initialState = {
    books: []
}

export const dbase = {
    namespaced: true,
    state: initialState,
    actions: {
        addBookToDb ({ commit }, book) {
            return api.saveBook(book)
        },
        async getBooks ({ commit }) {
            let books = await api.getBooks()
            commit('setBooks', books)
        },
        deleteBookFromDb ({ commit }) {
            return api.deleteBase()
        }
    },
    mutations: {
        setBooks(state, books) {
            state.books = books;
        },
    }
}