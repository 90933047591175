<template>
  <div>
    <b-container>
      <b-form-group class="mb-0" label-cols="5" label-size="sm" label="Draught (m)">
        <b-form-spinbutton id="sb-inline-draught" size="sm" v-model="routes.draught" min="1" step="0.2" max="25" locale="en"></b-form-spinbutton>
      </b-form-group>

      <b-form-group class="mb-0" label-cols="5" label-size="sm" label="Height (m)">
        <b-form-spinbutton id="sb-inline-height" size="sm" v-model="routes.height" min="1" step="0.2" max="50" locale="en"></b-form-spinbutton>
      </b-form-group>

      <b-form-group class="mb-0" label-cols="5" label-size="sm" label="Radius (m)">
        <b-form-spinbutton id="sb-inline-radius" size="sm" v-model="routes.radius" min="10" step="10" max="500" locale="en"></b-form-spinbutton>
      </b-form-group>

      <b-form-group class="mb-0" label-cols="5" label-size="sm" label="XTE.L (m)">
        <b-form-spinbutton id="sb-inline-xte-l" size="sm" v-model="routes.xtel" min="10" step="10" max="3000" locale="en"></b-form-spinbutton>
      </b-form-group>

      <b-form-group class="mb-0" label-cols="5" label-size="sm" label="XTE.R (m)">
        <b-form-spinbutton id="sb-inline-xte-r" size="sm" v-model="routes.xter" min="10" step="10" max="3000" locale="en"></b-form-spinbutton>
      </b-form-group>

      <b-form-group class="mb-0" label-cols="5" label-size="sm" label="Speed (knots)">
        <b-form-spinbutton id="sb-inline-speed" size="sm" v-model="routes.speed" min="1" step="1" max="50" locale="en"></b-form-spinbutton>
      </b-form-group>

      <b-form-group class="mb-0" label-cols="5" label-size="sm" label="Start date">
        <b-form-datepicker id="sb-inline-date" size="sm" v-model="routes.date" placeholder="Select a date" locale="en"></b-form-datepicker>
      </b-form-group>

      <b-form-group class="mb-0" label-cols="5" label-size="sm" label="Start time">
        <b-form-timepicker id="sb-inline-time" :hour12=false size="sm" v-model="routes.time" placeholder="Select a time" locale="en"></b-form-timepicker>
      </b-form-group>

      <b-form-group class="mb-0" label-cols="5" label-size="sm" label="Track ratio">
        <b-form-spinbutton id="sb-inline-ratio" size="sm" v-model="routes.ratio" min="0.1" step="0.1" max="0.9" locale="en"></b-form-spinbutton>
      </b-form-group>

      <b-form-group class="mb-0" label-cols="5" label-size="sm" label="Shallow water ratio">
        <b-form-spinbutton id="sb-inline-shallowratio" size="sm" v-model="routes.shallowratio" min="1" step="0.5" max="10" locale="en"></b-form-spinbutton>
      </b-form-group>

      <b-form-group class="mb-0" label-cols="5" label-size="sm" label="Avoid shallow water strength">
        <b-form-spinbutton id="sb-inline-shallowstrength" size="sm" v-model="routes.shallowstrength" min="1" step="0.5" max="10" locale="en"></b-form-spinbutton>
      </b-form-group>

      <b-form-group class="mb-0" label-cols="5" label-size="sm" label="Avoid distance">
        <b-form-spinbutton id="sb-inline-avoiddistance" size="sm" v-model="routes.avoiddistance" min="0" step="10" max="3000" locale="en"></b-form-spinbutton>
      </b-form-group>

      <b-form-group class="mb-0" label-cols="10" label-size="sm" label="Recommended tracks">
        <b-form-checkbox id="sb-inline-use-recommended" size="sm" v-model="routes.recommended" locale="en"></b-form-checkbox>
      </b-form-group>



    </b-container>

  </div>
</template>

<script>

import {eventBus} from "@/main";
import store from "@/store";
import {updateRoute} from "@/helpers/lsHelpers";

export default {
  data() {
    return {
      routes: {
        draught: this.$store.state.routes.route_settings.draught,
        height: this.$store.state.routes.route_settings.height,
        radius: this.$store.state.routes.route_settings.radius,
        xtel: this.$store.state.routes.route_settings.xtel,
        xter: this.$store.state.routes.route_settings.xter,
        date: this.$store.state.routes.route_settings.date,
        time: this.$store.state.routes.route_settings.time,
        speed: this.$store.state.routes.route_settings.speed,
        ratio: this.$store.state.routes.route_settings.ratio,
        shallowratio: this.$store.state.routes.route_settings.shallowratio,
        shallowstrength: this.$store.state.routes.route_settings.shallowstrength,
        avoiddistance: this.$store.state.routes.route_settings.avoiddistance ?? 120,
        recommended: true,
      }
    }
  },
  watch: {
    'routes.draught'() {
      this.saveData()
      this.keepRouteSettings()
    },
    'routes.height'() {
      this.saveData()
      this.keepRouteSettings()
    },
    'routes.radius'() {
      this.saveData()
      this.keepRouteSettings()
    },
    'routes.xtel'() {
      this.saveData()
      this.keepRouteSettings()
      eventBus.$emit('xte-changed')
    },
    'routes.xter'() {
      this.saveData()
      this.keepRouteSettings()
      eventBus.$emit('xte-changed')
    },
    'routes.speed'() {
      this.saveData()
      this.keepRouteSettings()
    },
    'routes.date'() {
      this.saveData()
      this.keepRouteSettings()
      eventBus.$emit('route-datetime-changed')
    },
    'routes.time'() {
      this.saveData()
      this.keepRouteSettings()
      eventBus.$emit('route-datetime-changed')
    },
    'routes.ratio'() {
      this.saveData()
      this.keepRouteSettings()
    },
    'routes.shallowratio'() {
      this.saveData()
      this.keepRouteSettings()
    },
    'routes.shallowstrength'() {
      this.saveData()
      this.keepRouteSettings()
    },
    'routes.avoiddistance'() {
      this.saveData()
      this.keepRouteSettings()
    },
  },
  mounted() {
    this.timer = setInterval(this.checkDepthSettings, 5000);

    eventBus.$off('keep-route-settings')
    eventBus.$off('load-route-data')
    eventBus.$off('restore-route')

    eventBus.$on('keep-route-settings', () => {
      this.keepRouteSettings()
    })

    eventBus.$on('load-route-data', () => {
      this.loadData()
    })

    eventBus.$on('restore-route', () => {
      this.routes.draught = this.$store.state.routes.route_settings.draught
      this.routes.height = this.$store.state.routes.route_settings.height
      this.routes.radius = this.$store.state.routes.route_settings.radius
      this.routes.xtel = this.$store.state.routes.route_settings.xtel
      this.routes.xter = this.$store.state.routes.route_settings.xter
      this.routes.no_go = this.$store.state.routes.route_settings.no_go
    })
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.keepRouteSettings()
  },
  methods: {
    loadData() {
      let routes = JSON.parse(localStorage.getItem('routes'))['route_temp_2']
      if (routes) {
        this.routes = routes
      }
    },
    saveData() {
      updateRoute('route_temp_2', this.routes)
    },
    keepRouteSettings() {
      store.commit('routes/setRouteSettings', {
        draught: this.routes.draught, height: this.routes.height, speed: this.routes.speed,
        radius: this.routes.radius, xtel: this.routes.xtel, xter: this.routes.xter, recommended: this.routes.recommended,
        date: this.routes.date, time: this.routes.time, ratio: this.routes.ratio, shallowratio: this.routes.shallowratio,
        shallowstrength: this.routes.shallowstrength, avoiddistance: this.routes.avoiddistance
      })
    },
    checkDepthSettings() {
      eventBus.$emit('change-nogo');
    },
  },
}
</script>
