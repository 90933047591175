import {file} from "paths.macro";
import store from "@/store";

const initialState = {
    version: ''
}

export const version = {
    namespaced: true,
    state: localStorage.getItem('version') ? JSON.parse(localStorage.getItem('version')) :
        initialState,
    mutations: {
        setVersion(state, version) {
            state.version = version;
        }
    }
}