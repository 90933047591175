import L from "leaflet";
import {eventBus} from "@/main";

export function createMoveControl() {

    return L.Control.Button = L.Control.extend({
        options: {
            position: 'topright'
        },
        onAdd: function (map) {
            let container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');

            let button = L.DomUtil.create('input', 'icon12', container);
            button.value = "←";
            button.type = "button";
            button.title = "move point left"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click', function () {
                eventBus.$emit('move-left')
            });

            button = L.DomUtil.create('input', 'leaflet-control-custom', container);
            button.value = "↑";
            button.type = "button";
            button.title = "move point up"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click', function () {
                eventBus.$emit('move-up')
            });

            button = L.DomUtil.create('input', 'leaflet-control-custom', container);
            button.value = "↓";
            button.type = "button";
            button.title = "move point down"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click', function () {
                eventBus.$emit('move-down')
            });

            button = L.DomUtil.create('input', 'leaflet-control-button', container);
            button.value = "→";
            button.type = "button";
            button.title = "move point right"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click', function () {
                eventBus.$emit('move-right')
            });

            button = L.DomUtil.create('input', 'leaflet-control-button', container);
            button.value = "‹";
            button.type = "button";
            button.title = "move leg left"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click', function () {
                eventBus.$emit('move-leg-left')
            });

            button = L.DomUtil.create('input', 'leaflet-control-button', container);
            button.value = "›";
            button.type = "button";
            button.title = "move leg right"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click', function () {
                eventBus.$emit('move-leg-right')
            });

            button = L.DomUtil.create('input', 'leaflet-control-button', container);
            button.value = "×";
            button.type = "button";
            button.title = "remove point"
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click', function () {
                eventBus.$emit('move-wp-remove')
                setTimeout(() => {
                    eventBus.$emit('check-safety')
                }, 500);

            });

            container.style.backgroundColor = 'white';
            container.style.borderStyle = 'none'

            return container;
        },
        onRemove: function (map) {
        },
    });
}

export function createWPControl() {

    return L.Control.Button = L.Control.extend({
        options: {
            position: 'topright'
        },
        onAdd: function (map) {
            eventBus.$off('show-wp-index1')
            eventBus.$on('show-wp-index1', (index) => {
                button3.value = index === -1 ? ' ' : "WP" + index

                if (index === -1) {
                    button.style.display = 'inline'
                    button1.style.display = 'inline'
                    button3.style.display = 'none'
                } else {
                    eventBus.$emit('move-wp-start', index)
                    button.style.display = 'inline'
                    button1.style.display = 'inline'
                    button3.style.display = 'inline'
                }
            })

            let container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
            let button = L.DomUtil.create('input', 'icon12', container);
            button.value = "<";
            button.type = "button";
            L.DomEvent.disableClickPropagation(button);
            L.DomEvent.on(button, 'click', function(){
                eventBus.$emit('move-prev')
                console.log('click');
            });

            let button3 = L.DomUtil.create('input', 'leaflet-control-custom', container);
            button3.value = " ";
            button3.type = "button";
            button3.style.display = 'none'
            L.DomEvent.disableClickPropagation(button3);
            L.DomEvent.on(button3, 'click', function(){
                console.log('click2');
            });

            let button1 = L.DomUtil.create('input', 'leaflet-control-button', container);
            button1.value = ">";
            button1.type = "button";
            L.DomEvent.disableClickPropagation(button1);
            L.DomEvent.on(button1, 'click', function(){
                console.log('click3');
                eventBus.$emit('move-next')
            });

            container.style.backgroundColor = 'white';
            container.style.borderStyle = 'none'

            return container;
        },
        onRemove: function(map) {},
    });
}

export function createClearControl() {
    return L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            let container = L.DomUtil.create('input');
            container.type = "button";
            container.title = "Clear route ";
            container.value = "Clear route";
            container.style.backgroundColor = 'white';
            container.style.borderRadius = '5px'
            container.style.borderColor = '#a1a1a1'
            container.style.borderStyle = 'solid'
            container.style.width = '120px';
            container.style.height = '28px';

            container.onmouseover = function () {
                container.style.backgroundColor = '#efefef';
            }
            container.onmouseout = function () {
                container.style.backgroundColor = 'white';
            }

            container.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                map.closePopup();
                eventBus.$emit('control-clear-route')
                eventBus.$emit('control-clear-global-route')
            }
            return container;
        }
    })
}

export function createSafetyControl() {
    return L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            let container = L.DomUtil.create('input');
            container.type = "button";
            container.title = "Check route safety ";
            container.value = "Check route safety";
            container.style.backgroundColor = 'white';
            container.style.borderRadius = '5px'
            container.style.borderColor = '#a1a1a1'
            container.style.borderStyle = 'solid'
            container.style.width = '120px';
            container.style.height = '28px';

            container.onmouseover = function () {
                container.style.backgroundColor = '#efefef';
            }
            container.onmouseout = function () {
                container.style.backgroundColor = 'white';
            }

            container.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                map.closePopup();
                eventBus.$emit('check-safety')
            }
            return container;
        }
    })
}

export function createSafetyWPControl() {
    return L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            let container = L.DomUtil.create('input');
            container.type = "button";
            container.title = "Check point safety ";
            container.value = "Check point safety";
            container.style.backgroundColor = 'white';
            container.style.borderRadius = '5px'
            container.style.borderColor = '#a1a1a1'
            container.style.borderStyle = 'solid'
            container.style.width = '120px';
            container.style.height = '28px';

            container.onmouseover = function () {
                container.style.backgroundColor = '#efefef';
            }
            container.onmouseout = function () {
                container.style.backgroundColor = 'white';
            }

            container.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                map.closePopup();
                eventBus.$emit('check-safety')
            }
            return container;
        }
    })
}

export function createRecalculateControl() {
    return L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            let container = L.DomUtil.create('input');
            container.type = "button";
            container.title = "Recalculate route ";
            container.value = "Recalculate route";
            container.style.backgroundColor = 'white';
            container.style.borderRadius = '5px'
            container.style.borderColor = '#a1a1a1'
            container.style.borderStyle = 'solid'
            container.style.width = '120px';
            container.style.height = '28px';

            container.onmouseover = function () {
                container.style.backgroundColor = '#efefef';
            }
            container.onmouseout = function () {
                container.style.backgroundColor = 'white';
            }

            container.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                map.closePopup();
                eventBus.$emit('control-recalculate-all')
            }
            return container;
        }
    })
}

export function createRecalculateWPControl() {
    return L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            let container = L.DomUtil.create('input');
            container.type = "button";
            container.title = "Recalculate point ";
            container.value = "Recalculate point";
            container.style.backgroundColor = 'white';
            container.style.borderRadius = '5px'
            container.style.borderColor = '#a1a1a1'
            container.style.borderStyle = 'solid'
            container.style.width = '120px';
            container.style.height = '28px';

            container.onmouseover = function () {
                container.style.backgroundColor = '#efefef';
            }
            container.onmouseout = function () {
                container.style.backgroundColor = 'white';
            }

            container.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                map.closePopup();
                eventBus.$emit('move-wp-recalculate')
            }
            return container;
        }
    })
}

export function createMakeRouteControl() {
    return L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            eventBus.$off('toggle-mode-after-auto')
            eventBus.$off('toggle-mode-after-edit-zones')

            eventBus.$on('toggle-mode-after-auto', () => {
                // alert(1)
                container.button_state = true
                container.value = "Auto-mode OFF"
            })

            eventBus.$on('toggle-mode-after-edit-zones', () => {
                container.button_state = false
                container.value = "Auto-mode ON"
            })

            let container = L.DomUtil.create('input');
            container.button_state = true;
            container.type = "button";
            container.title = "Toggle ";
            container.value = "Auto-mode OFF";
            container.style.backgroundColor = 'white';
            container.style.borderRadius = '5px'
            container.style.borderColor = '#a1a1a1'
            container.style.borderStyle = 'solid'
            container.style.width = '120px';
            container.style.height = '28px';

            container.onmouseover = function () {
                container.style.backgroundColor = '#efefef';
            }
            container.onmouseout = function () {
                container.style.backgroundColor = 'white';
            }

            container.onclick = (e) => {
                console.log("event ", e)
                e.stopPropagation();
                e.preventDefault();
                container.button_state = !container.button_state;
                map.closePopup();

                eventBus.$emit('toggle-auto-mode', container.button_state)
                container.value = container.button_state ? "Auto-mode OFF" : "Auto-mode ON"
            }
            return container;
        }
    })
}

export function createMeasureControl() {
    let measureControl;
    let options = {position:'bottomleft',
        unit:'nauticalmiles',
        showBearings:true,
        clearMeasurementsOnStop: false,
        showClearControl: true,
        showUnitControl: true,
        measureControlLabel: "&#8737",
        distanceShowSameUnit: true,
        measureControlTitleOn: 'Turn on measure tool',
        measureControlTitleOff: 'Turn off measure tool',
    };
    measureControl = L.control.polylineMeasure(options)

    return measureControl
}
export function createWeatherTextBox() {
    let textbox   = L.Control.extend({
        onAdd: function() {

            var text = L.DomUtil.create('div');
            text.id = "weather_text";
            text.innerHTML = ""
            return text;
        },

    });
    return new textbox({ position: 'bottomleft' })
}

export function createMakeNoGoControl() {
    return L.Control.extend({
        options: {
            position: 'topleft'
        },

        onAdd: (map) => {
            eventBus.$off('toggle-mode-after-auto')
            eventBus.$on('toggle-mode-after-auto', () => {
                container.button_state = true
                container.value = "Edit NoGo zones"
            })

            let container = L.DomUtil.create('input');
            container.button_state = true;
            container.type = "button";
            container.title = "Toggle ";
            container.value = "Edit NoGo zones";
            container.style.backgroundColor = 'white';
            container.style.borderRadius = '5px'
            container.style.borderColor = '#a1a1a1'
            container.style.borderStyle = 'solid'
            container.style.width = '112px';
            container.style.height = '28px';

            container.onmouseover = function () {
                container.style.backgroundColor = '#efefef';
            }
            container.onmouseout = function () {
                container.style.backgroundColor = 'white';
            }

            container.onclick = (e) => {
                console.log("event ", e)
                e.stopPropagation();
                e.preventDefault();
                container.button_state = !container.button_state;
                map.closePopup();

                eventBus.$emit('toggle-nogo-edit-mode', container.button_state)
                if (container.button_state)
                    eventBus.$emit('save-nogo')

                container.value = container.button_state ? "Edit NoGo zones" : "Exit editing"
            }
            return container;
        }
    });
}

export function createEasyButtons(map) {
    new L.Control.EasyButton({
        position: 'bottomleft',
        states: [{
            icon: 'bi bi-pencil-square',
            title: 'Route by coordinates',
            onClick: (btn) => {
                eventBus.$emit('route-by-coordinates')
            }
        }]
    }).addTo(map)

    new L.Control.EasyButton({
        position: 'bottomleft',
        states: [{
            icon: 'bi bi-search',
            title: 'Search',
            onClick: (btn) => {
                eventBus.$emit('search-on-map')
            }
        }]
    }).addTo(map)
}