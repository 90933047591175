import L from 'leaflet';

import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap'
import "bootstrap/dist/css/bootstrap.css"


import "./extensions/L.Control.MapCenterCoord.min.js"
import "./extensions/L.Control.MapCenterCoord.min.css"

import store from "@/store";
import {eventBus} from "@/main";

import "./extensions/leaflet.scalefactor"
import "./extensions/leaflet.scalefactor.css"

import './controls.css'



export class MyLeaf {
    init(el, url, center, zoom, minZoom=2, maxZoom=20) {
        this.map = new L.map(el, {zoomControl: false, preferCanvas: true, scrollWheelZoom: 'center'},
        ).setView(center, zoom);

        // L.control.scale({position:'bottomright', imperial: false}).addTo(this.map);
        L.control.scalefactor({position:'bottomright'}).addTo(this.map);

        let southWest = L.latLng(-85, -180.0),
            northEast = L.latLng(85, 180.0),
            mybounds = L.latLngBounds(southWest, northEast);

        this.currentMapsURL = url;

        L.control.mapCenterCoord().addTo(this.map);
        this.mainLayer = L.tileLayer(this.currentMapsURL, {
            // zoomControl: false,
            minZoom: minZoom,
            maxZoom: maxZoom,
            maxBounds: mybounds,
            // attribution: "Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri"
            attribution: "\"Map data &copy; <a href='http://probki.net/b2b'>Geophone OY</a>"
        }).addTo(this.map);

        L.control.zoom({
            position: 'bottomright'
        }).addTo(this.map);

        let leafletItems = document.getElementsByClassName( 'leaflet-control-attribution' )
        leafletItems.forEach(item => {
            item.style.display = 'none';
        })
        // document.getElementsByClassName( 'leaflet-control-attribution' )[0].style.display = 'none';

        // L.control.scalefactor({position: 'bottomleft'}).addTo(this.map);

        // L.Control.textbox = L.Control.extend({
        //     onAdd: function(map) {
        //         let text = L.DomUtil.create('div');
        //         text.id = "chart_text_order";
        //         text.innerHTML = "<span class='chart-text'>&nbsp;New order&nbsp;</span>"
        //         return text;
        //     },
        //     onRemove: function(map) {
        //         // Nothing to do here
        //     },
        //     updateContent: function(text) {
        //         this.getContainer().innerHTML = text;
        //     }
        // });
        // L.control.textbox = function(opts) { return new L.Control.textbox(opts);}
        // orderTextOnChart = L.control.textbox({ position: 'topleft' }).addTo(this.map);
        //
        // L.control.zoom({
        //     position: 'topleft'
        // }).addTo(this.map);
        //

        // eventBus.$on('set-new-order-text-on-chart', () => {
        //     orderTextOnChart.updateContent("<span class='chart-text'>&nbsp;Order №1248 (" + new Date().toLocaleDateString() + ")&nbsp;</span>")
        // })

        // this.map('map', { searchControl: {layer: this.mainLayer} });
        // L.control.search("aaa").addTo(this.map);
        // L.control.searchControl().addTo(this.map);

        //
        // L.control.searchControl().addTo(this.map)

        this.map.on('moveend', () => {
            let coorinates = this.map.getCenter();
            // localStorage.setItem('mapCenter', JSON.stringify([coorinates.lat, coorinates.lng]));
            store.commit("leaflet/setMapCenter", coorinates)
        })

        this.map.on('zoomend', () => {
            let zoom = this.map.getZoom();
            // localStorage.setItem('mapZoom', JSON.stringify(zoom));
            store.commit("leaflet/setMapZoom", zoom)
        })

        this.map.setMaxBounds(mybounds)
        return this.map;
    }
    moveTo(lat, lon) {
        this.map.setView([lat, lon]);
    }
    panToZone(bounds) {
        this.map.fitBounds(bounds);
    }
    redrawMap(URL, coordinates, zoom_level) {
        this.mainLayer.remove();
        this.map.setView(coordinates, zoom_level);
        this.mainLayer = L.tileLayer(URL);
        this.mainLayer.addTo(this.map);
    }
}




