<template>
  <div>
    <b-modal v-model="selectShipQuestion" hide-footer @hide="selectNewShip()" title="Select Ship">
      <p>Use the same ship for the new Order1?</p>
      <b-button class="mt-2 ml-1" variant="info" @click="useSelectedShip()">Yes</b-button>
      <b-button class="mt-2 ml-1" variant="info" @click="selectNewShip()">No</b-button>
    </b-modal>
  </div>
</template>

<script>
import router from "@/router";
import {eventBus} from "@/main";
import localforage from "localforage";

export default {
  data() {
    return {
      selectShipQuestion: false
    }
  },
  mounted() {
    // this.$store.commit('current/clearCart')
    this.$store.dispatch('addS63Items', [])



    if (!this.$store.state.proto.ship_selected || this.$store.state.proto.restoredOrder) {
      router.push({name: 'ships'});
      return;
    } else {
      this.selectShipQuestion = true
      // this.$refs.selectShipQuestion.show()
    }
    this.$store.commit('proto/setRestoredOrder', null)
    this.$store.dispatch('proto/updateOrder', {
      update: false,
    })
  },
  methods: {
    selectNewShip() {
      router.push('ships')
    },
    useSelectedShip() {
      this.$store.commit("routes/useRouteForOrder", false)
      this.$store.commit("routes/resetStorage")
      this.$store.commit('proto/cancelOrderButKeepShip')
      eventBus.$emit('ship-selected-from-chart')
      this.$store.dispatch('proto/updateOrder', {step: "creating"})
      localStorage.removeItem('route')
      localStorage.removeItem('cart')
      localforage.removeItem('route')
      localforage.removeItem('cart')

      // localStorage.removeItem('proto')
      router.push('charts')
    },
  }
}
</script>

<style scoped>

</style>