<template>
  <div>
    <div id="reader"></div>
  </div>
</template>

<script>
import {Html5QrcodeScanner} from "html5-qrcode";
import {eventBus} from "@/main";

export default {
  name: "QRScanner",
  mounted() {
    eventBus.$on('start-qr-scan', () => {
      setTimeout( () => {
        // let html5QrcodeScanner = new Html5QrcodeScanner(
        //     "reader", { fps: 10, qrbox: 250 });
        html5QrcodeScanner.render(onScanSuccess);
      }, 1000)
    })

    let qrboxFunction = function(viewfinderWidth, viewfinderHeight) {
      let minEdgePercentage = 0.7; // 70%
      let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
      let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
      return {
        width: qrboxSize,
        height: qrboxSize
      };
    }

    let html5QrcodeScanner = new Html5QrcodeScanner(
        "reader", {
          qrbox: qrboxFunction,
          // qrbox: { width: 250, height: 250 },
          fps: 10,
          disableFlip: true,
          focusMode: "continuous",
          aspectRatio: 1,
          // videoConstraints: {
          //   facingMode: { exact: "environment" },
          // },
          showTorchButtonIfSupported: true, useBarCodeDetectorIfSupported: true, showZoomSliderIfSupported: true,
          defaultZoomValueIfSupported: 2
        },
        false);

    function onScanSuccess(decodedText, decodedResult) {
      // Handle on success condition with the decoded text or result.
      console.log(`Scan result: ${decodedText}`, decodedResult);
      // ...
      html5QrcodeScanner.clear();
      // ^ this will stop the scanner (video feed) and clear the scan area.

      eventBus.$emit('scan-success', decodedResult)
    }

    // html5QrcodeScanner.render(onScanSuccess);

    // setTimeout( () => {
    //   // let html5QrcodeScanner = new Html5QrcodeScanner(
    //   //     "reader", { fps: 10, qrbox: 250 });
    //   html5QrcodeScanner.render(onScanSuccess);
    // }, 1000)

  },
}
</script>

<style scoped>

</style>