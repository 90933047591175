import L from "leaflet";
import {NavLeaf} from "./nav-leaf";
import {eventBus} from "@/main";
import store from '../store'
import './routes/route-styles.css'
import "./extensions/leaflet-search/leaflet-search.min.js"
import "./extensions/leaflet-search/leaflet-search.min.css"

import '@fortawesome/fontawesome-free/js/fontawesome'
//import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
// import '@fortawesome/fontawesome-free/js/brands'

// import { faLink } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import iconImg from "@/assets/ship_1tx_green.png";

function addControl(name, event) {

    let customControl = L.Control.extend({
        options: {
            position: 'topright'
        },

        onAdd: (map) => {
            let container = L.DomUtil.create('input');
            container.button_state = true;
            container.type = "button";
            container.title = "Toggle " + name.toLowerCase() + " drawing";
            container.value = name + ' ☑';
            container.style.backgroundColor = 'white';

            container.style.width = '98px';
            container.style.height = '24px';

            container.onmouseover = function () {
                container.style.backgroundColor = 'lightgray';
            }
            container.onmouseout = function () {
                container.style.backgroundColor = 'white';
            }

            container.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                container.button_state = !container.button_state;
                eventBus.$emit(event, container.button_state)
                container.value = container.button_state ? name + ' ☑' : name + ' ☐'
            }
            return container;
        }
    });

    return new customControl()

}

export class ShipLeaf extends NavLeaf {
    customControl1;
    customControl2;
    customControl3;
    customControl4;
    customControl5;

    textControl;

    positionMarker = null
    positionLogMarker = null
    destinationMarker = null

    routeWPS = []
    routeLegs = null
    trackWPS = []
    trackLegs = null

    routeLogWPS = []
    routeLogLegs = null
    trackLogWPS = []
    trackLogLegs = null

    layerControl


    constructor() {
        super();

        this.destLayer = L.layerGroup();
        this.trackLayer = L.layerGroup();
        this.routeLayer =L.layerGroup();
        this.posLayer =L.layerGroup();
        this.otherLayer =L.layerGroup();
    }

    async init(el) {
        super.init(el);

        this.destLayer.addTo(this.map);
        this.routeLayer.addTo(this.map);
        this.trackLayer.addTo(this.map);
        this.posLayer.addTo(this.map)
        this.otherLayer.addTo(this.map)

        this.layerControl = L.control.layers().addTo(this.map);

        let destinationLayerGroup = L.layerGroup([this.destLayer]).addTo(this.map);
        let routeLayerGroup = L.layerGroup([this.routeLayer]).addTo(this.map);
        let trackLayerGroup = L.layerGroup([this.trackLayer]).addTo(this.map);
        let positionLayerGroup = L.layerGroup([this.posLayer]).addTo(this.map);
        let otherLayerGroup = L.layerGroup([this.otherLayer]);

        this.layerControl.addOverlay(destinationLayerGroup, "Destination");
        this.layerControl.addOverlay(routeLayerGroup, "Route");
        this.layerControl.addOverlay(trackLayerGroup, "Track");
        this.layerControl.addOverlay(positionLayerGroup, "Position");
        this.layerControl.addOverlay(otherLayerGroup, "Other ships");


        // let overlayMaps = {
        //     "Cities": cities
        // };
        // this.layerControl = L.control.layers(this.shipsLayer).addTo(this.map);

        // store.commit("fickle/setQRInfoDebug")

        // this.customControl = new customControl()
        // this.customControl1 = addControl("Destination", 'toggle-destination-drawing')
        // this.map.addControl(this.customControl1)
        // this.customControl2 = addControl("Route", 'toggle-route-drawing')
        // this.map.addControl(this.customControl2)
        // this.customControl3 = addControl("Track", 'toggle-track-drawing')
        // this.map.addControl(this.customControl3)
        // this.customControl4 = addControl("Position", 'toggle-position-drawing')
        // this.map.addControl(this.customControl4)
        // this.customControl5 = addControl("Other ships", 'toggle-other-ships-drawing')
        // this.map.addControl(this.customControl5)

        L.Control.textbox = L.Control.extend({
            onAdd: function(map) {
                let text = L.DomUtil.create('div');
                text.id = "chart_text_order";
                text.innerHTML = ""
                return text;
            },
            onRemove: function(map) {
                // Nothing to do here
            },
            updateContent: function(text) {
                this.getContainer().innerHTML = text;
            }
        });

        L.control.textbox = function(opts) { return new L.Control.textbox(opts);}
        this.textControl = L.control.textbox({ position: 'topleft' }).addTo(this.map);

        eventBus.$on('toggle-destination-drawing', (state) => {
            console.log('toggle-destination-drawing', state)
            if (state)
                this.showDestination(shipInfo.route.points[shipInfo.route.points.length-1].lat, shipInfo.route.points[shipInfo.route.points.length-1].lon)
            else
                this.showDestination(0,0,false)
        })
        eventBus.$on('toggle-route-drawing', (state) => {
            console.log('toggle-route-drawing', state)
            if (state)
                this.showRoute()
            else
                this.showRoute(false)
        })
        eventBus.$on('toggle-track-drawing', (state) => {
            console.log('toggle-track-drawing', state)
            if (state)
                this.showTrack()
            else
                this.showTrack(false)
        })
        eventBus.$on('toggle-position-drawing', (state) => {
            console.log('toggle-position-drawing', state)
            if (state)
                this.showPosition(shipInfo.POS.lat,shipInfo.POS.lon)
            else
                this.showPosition(0,0,false)
        })
        eventBus.$on('toggle-other-ships-drawing', (state) => {
            console.log('toggle-other-ships-drawing', state)
        })

        // eventBus.$on('show-log-on-map', (payload) => {
        //     this.map.flyTo(L.latLng(payload.lat, payload.lon), 8)
        // })
        eventBus.$off('show-log-on-map')
        eventBus.$on('show-log-on-map', (time) => {
            // alert(time)

            let logs = store.state.fickle.qr.shipLogs
            let objIndex = logs.findIndex((obj => obj.permit === store.state.fickle.qr.selectedPermit));
            // let shipInfo = store.state.fickle.qr.shipLogs[objIndex].logs[store.state.fickle.qr.shipLogs[objIndex].logs.length - 1].info

            logs = store.state.fickle.qr.shipLogs[objIndex].logs
            let objIndex1 = logs.findIndex((obj => obj.time === time));
            let data = logs[objIndex1].info
            console.log(data)

            // let data = store.state.fickle.qr.shipInfo.logs[index]

            // const mapBounds = this.map.getBounds();
            // const x = mapBounds.getSouthEast();
            // const p = this.map.latLngToContainerPoint(x);
            // const p_WithOffset = p.subtract(L.point(70, 70));
            // const x_WithOffset = this.map.containerPointToLatLng(p_WithOffset);


            this.map.flyTo(L.latLng(data.POS.lat, data.POS.lon), 8)

            // let popup = L.popup({
            //     offset: [x_WithOffset, 0]
            // }).setLatLng(L.latLng(data.lat, data.lon)).setContent("Test Text")
            //     .openOn(this.map);

            let text = ''

            if (data.route.name)
                text += '&nbsp;Route: ' + data.route.name + '&nbsp;<br>'
            if (data.HDG !== undefined)
                text += '&nbsp;HDG: ' + data.HDG + '&nbsp;<br>'
            if (data.SOG !== undefined)
                text += '&nbsp;SOG: ' + data.SOG + '&nbsp;<br>'
            if (data.COG !== undefined)
                text += '&nbsp;COG: ' + data.COG + '&nbsp;<br>'
            if (data.ROT !== undefined)
                text += '&nbsp;ROT: ' + data.ROT + '&nbsp;<br>'
            if (data.DTG !== undefined)
                text += '&nbsp;DTG: ' + data.DTG + '&nbsp;<br>'
            if (data.TTG)
                text += '&nbsp;TTG: ' + data.TTG + '&nbsp;<br>'

            let wrappedText = "<span class='chart-text-simple'>" + text + "</span>"

            this.textControl.updateContent(wrappedText)


            // let text = 'HDG: ' + data.HDG + ', SOG: ' + data.SOG + ', COG: ' + data.COG + ', ROT: ' + data.ROT
            // if (data.DTG || data.TTG)
            //     text = text +  '&nbsp;<br>&nbsp;'
            // if (data.DTG)
            //     text = text + 'DTG: ' + data.DTG
            // if (data.TTG)
            //     text = text + 'DTG: ' + data.TTG
            // if (data.message) {
            //     text = text + '&nbsp;<br>&nbsp;' + data.message
            // }
            // let wrappedText = "<span class='chart-text-simple'>&nbsp;" + text + "&nbsp;</span>"
            //
            // this.textControl.updateContent(wrappedText)


                // let popup = L.popup(L.latLng(data.POS.lat, data.POS.lon),
                // {content: 'HDG: ' + data.HDG + '<br>' +
                //         'SOG: ' + data.SOG + '<br>' +
                //         'COG: ' + data.COG + '<br>' +
                //         'ROT: ' + data.ROT + '<br>' +
                //         'DTG: ' + data.DTG + '<br>' +
                //         'TTG: ' + data.TTG,
                //     offset: [0, -30],
                //     // pane: 'labels'
                // })
                // .openOn(this.map);
            this.showLogPosition(data.POS.lat, data.POS.lon, data.HDG)
            this.showLogRoute(data)
            this.showLogTrack(data)



            // let pane = this.map.createPane('fixed', document.getElementById('mapid'));
            // let marker = L.marker([60, 27]).addTo(this.map);

            // let popup1 = L.popup({
            //     pane: 'fixed', // Указываем нужный pane
            //     className: 'popup-fixed',
            //     autoPan: false,
            // }).setContent('First popup');
            //
            // marker.bindPopup(popup1);


        })

        new L.Control.EasyButton({
            position: 'bottomleft',
            states: [{
                icon: 'bi bi-search',
                title: 'Search',
                onClick: (btn) => {
                    eventBus.$emit('search-on-map')
                }
            }]
        }).addTo(this.map)

        // let shipInfo = store.state.fickle.qr.shipInfo

        let logs = store.state.fickle.qr.shipLogs
        let objIndex = logs.findIndex((obj => obj.permit === store.state.fickle.qr.selectedPermit));
        let shipInfo = store.state.fickle.qr.shipLogs[objIndex].logs[store.state.fickle.qr.shipLogs[objIndex].logs.length - 1].info

        // let logs = store.state.fickle.qr.shipLogs
        // let objIndex = logs.findIndex((obj => obj.permit === row.primarInfo.primarPermit));
        // console.log(objIndex)


        this.showPosition(shipInfo.POS.lat,shipInfo.POS.lon)
        this.showDestination(shipInfo.route.points[shipInfo.route.points.length-1].lat, shipInfo.route.points[shipInfo.route.points.length-1].lon)
        this.showRoute()
        this.showTrack()

        eventBus.$on('show-from-search', (payload) => {
            this.map.setView([payload.lat, payload.lon], 8);
        })
    }

    setTileLayerWithDraught() {
        if (this.navalLayer) this.navalLayer.remove();
        // const draught = document.getElementById('sb-inline-draught').defaultValue;
        const draught = 10
        const url = "https://nogotiles.bgeo.fi:6007/nogo/{z}/{x}/{y}?depth=" + draught;
        this.navalLayer = L.tileLayer(url, {
            minZoom: 2,
            maxZoom: 20,
        }).addTo(this.map)
    }

    showPosition(lat, lon, flag = true) {
        if (!flag) {
            this.map.removeLayer(this.positionMarker)
            return
        }

        let shipInfo = store.state.fickle.qr.shipInfo

        let iconImg = require("../assets/ship_1tx_green.png");
        let icon = L.icon({
            iconUrl: iconImg,
            iconSize: [20, 40],
            iconAnchor: [10, 20],

        });
        this.positionMarker = new L.marker([lat, lon], {icon: icon, rotationAngle: shipInfo.HDG});


        let text = ''

        if (shipInfo.route.name)
            text += '&nbsp;Route: ' + shipInfo.route.name + '&nbsp;<br>'
        if (shipInfo.HDG !== undefined)
            text += '&nbsp;HDG: ' + shipInfo.HDG + '&nbsp;<br>'
        if (shipInfo.SOG !== undefined)
            text += '&nbsp;SOG: ' + shipInfo.SOG + '&nbsp;<br>'
        if (shipInfo.COG !== undefined)
            text += '&nbsp;COG: ' + shipInfo.COG + '&nbsp;<br>'
        if (shipInfo.ROT !== undefined)
            text += '&nbsp;ROT: ' + shipInfo.ROT + '&nbsp;<br>'

        let wrappedText = "<span class='chart-text-simple'>" + text + "</span>"

        this.textControl.updateContent(wrappedText)


        // this.textControl.updateContent("<span class='chart-text-simple1'>&nbsp;" +
        //     'HDG: ' + shipInfo.HDG +
        //     ', SOG: ' + shipInfo.SOG +
        //     ', COG: ' + shipInfo.COG +
        //     ', ROT: ' + shipInfo.ROT +
        //     "&nbsp;</span>")

        // let toolTip = '<b>' + shipInfo.name + '</b><hr>' +
        //     'HDG: ' + shipInfo.HDG + '<br>' +
        //     'COG: ' + shipInfo.COG + '<br>' +
        //     'SOG: ' + shipInfo.SOG + '<br>' +
        //     'ROT: ' + shipInfo.ROT + '<br>'
        //
        // this.positionMarker.bindTooltip(toolTip, {permanent: false});
        this.positionMarker.addTo(this.posLayer);

        this.map.flyTo(L.latLng(lat, lon), 8)
    }

    showLogPosition(lat, lon, HDG) {
        if (this.positionLogMarker) {
            this.map.removeLayer(this.positionLogMarker)
        }

        if (this.positionMarker) {
            this.map.removeLayer(this.positionMarker)
        }

        if (this.routeLegs) {
            this.routeWPS.forEach(wp => {
                this.map.removeLayer(wp)
            })
            this.map.removeLayer(this.routeLegs)
        }

        if (this.trackLegs) {
            this.trackWPS.forEach(wp => {
                this.map.removeLayer(wp)
            })
            this.map.removeLayer(this.trackLegs)
        }

        let iconImg = require("../assets/ship_1tx_yellow.png");
        let icon = L.icon({
            iconUrl: iconImg,
            iconSize: [20, 40],
            iconAnchor: [10, 20],

        });
        this.positionLogMarker = new L.marker([lat, lon], {icon: icon, rotationAngle: HDG});

        // let toolTip = '<b>' + shipInfo.name + '</b><hr>' +
        //     'HDG: ' + shipInfo.HDG + '<br>' +
        //     'COG: ' + shipInfo.COG + '<br>' +
        //     'SOG: ' + shipInfo.SOG + '<br>' +
        //     'ROT: ' + shipInfo.ROT + '<br>'
        //
        // this.positionMarker.bindTooltip(toolTip, {permanent: false});
        this.positionLogMarker.addTo(this.posLayer);
        //
        // this.map.flyTo(L.latLng(lat, lon), 8)
    }

    showDestination(lat, lon, flag = true) {
        if (!flag) {
            this.map.removeLayer(this.destinationMarker)
            return
        }

        let shipInfo = store.state.fickle.qr.shipInfo

        const fontAwesomeIcon = L.divIcon({
            html: '<i class="fa fa-bullseye fa-2x" style="color:red"></i>',
            className: 'myDivIcon'
        });

        this.destinationMarker = new L.marker([lat, lon], {icon: fontAwesomeIcon});
        this.destinationMarker.addTo(this.destLayer);
    }

    showLogRoute(data) {
        if (this.routeLogLegs) {
            this.routeLogWPS.forEach(wp => {
                this.map.removeLayer(wp)
            })
            this.map.removeLayer(this.routeLogLegs)

        }

        let wpMarker = L.divIcon({
            className: '',
            iconSize: [3, 3],
            iconAnchor: [3, 3],
            html: "<div style='font-size: 8px'>&#x1F7E1;</div>"
        });

        let shipInfo = store.state.fickle.qr.shipInfo

        let pointsArray = []
        data.route.points.forEach(coordinates => {
            pointsArray.push({lat: coordinates.lat, lon: coordinates.lon})
            let point = new L.marker([coordinates.lat, coordinates.lon], {title: 'WP' + pointsArray.length, draggable: false, icon: wpMarker}).addTo(this.markerLayer);

            point.bindTooltip('WP' + pointsArray.length, {permanent: true}).openTooltip();

            this.routeLogWPS.push(point)
            point.addTo(this.markerLayer)
        })

        this.routeLogLegs = L.polyline(pointsArray, {color: 'green', weight: 2}).addTo(this.markerLayer);
    }

    showLogTrack(data) {

        if (this.trackLogLegs) {
            // this.trackLogWPS.forEach(wp => {
            //     this.map.removeLayer(wp)
            // })
            this.map.removeLayer(this.trackLogLegs)

        }

        let wpMarker = L.divIcon({
            className: '',
            iconSize: [3, 3],
            iconAnchor: [3, 3],
            html: "<div style='font-size: 8px'>&#x1F7E4;</div>"
        });

        let shipInfo = store.state.fickle.qr.shipInfo

        let pointsArray = []
        data.history.forEach(coordinates => {
            pointsArray.push({lat: coordinates.lat, lon: coordinates.lon})
            let point = new L.marker([coordinates.lat, coordinates.lon], {draggable: false, icon: wpMarker}).addTo(this.markerLayer);
            this.trackLogWPS.push(point)
            point.addTo(this.markerLayer)
        })

        this.trackLogLegs = L.polyline(pointsArray, {color: 'black', weight: 1}).addTo(this.markerLayer);
    }

    showRoute(flag = true) {
        if (!flag) {
            this.routeWPS.forEach(wp => {
                this.map.removeLayer(wp)
            })
            this.map.removeLayer(this.routeLegs)
            return
        }

        let wpMarker = L.divIcon({
            className: '',
            iconSize: [3, 3],
            iconAnchor: [3, 3],
            html: "<div style='font-size: 8px'>&#x1F7E1;</div>"
        });

        // let shipInfo = store.state.fickle.qr.shipInfo

        let logs = store.state.fickle.qr.shipLogs
        let objIndex = logs.findIndex((obj => obj.permit === store.state.fickle.qr.selectedPermit));
        let shipInfo = store.state.fickle.qr.shipLogs[objIndex].logs[store.state.fickle.qr.shipLogs[objIndex].logs.length - 1].info


        let pointsArray = []
        shipInfo.route.points.forEach(coordinates => {
            pointsArray.push({lat: coordinates.lat, lon: coordinates.lon})
            let point = new L.marker([coordinates.lat, coordinates.lon], {title: 'WP' + pointsArray.length, draggable: false, permanent: true, icon: wpMarker}).addTo(this.markerLayer)
            point.bindTooltip('WP' + pointsArray.length, {permanent: true}).openTooltip();
            // point.addTo(this.markerLayer);
            // point.openTooltip()
            this.routeWPS.push(point)
            point.addTo(this.routeLayer)
        })

        this.routeLegs = L.polyline(pointsArray, {color: 'green', weight: 2}).addTo(this.routeLayer);
    }

    showTrack(flag = true) {
        if (!flag) {
            this.trackWPS.forEach(wp => {
                this.map.removeLayer(wp)
            })
            this.map.removeLayer(this.trackLegs)
            return
        }

        let wpMarker = L.divIcon({
            className: '',
            iconSize: [3, 3],
            iconAnchor: [3, 3],
            html: "<div style='font-size: 8px'>&#x1F7E4;</div>"
        });

        // let shipInfo = store.state.fickle.qr.shipInfo

        let logs = store.state.fickle.qr.shipLogs
        let objIndex = logs.findIndex((obj => obj.permit === store.state.fickle.qr.selectedPermit));
        let shipInfo = store.state.fickle.qr.shipLogs[objIndex].logs[store.state.fickle.qr.shipLogs[objIndex].logs.length - 1].info


        let pointsArray = []
        shipInfo.history.forEach(data => {
            pointsArray.push({lat: data.lat, lon: data.lon})
            let point = new L.marker([data.lat, data.lon], {title: new Date( Date.parse(data.TIME) ), draggable: false, icon: wpMarker}).addTo(this.markerLayer);
            this.trackWPS.push(point)
            point.addTo(this.trackLayer)
        })

        this.trackLegs = L.polyline(pointsArray, {color: 'black', weight: 1}).addTo(this.trackLayer);
    }

}
