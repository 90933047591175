<template>
  <div class="mt-2">
    <b-form-select
        v-model="selected"
        :options="this.$parent.user_agreements"
        class="mb-3"
        value-field="index"
        text-field="title"
        disabled-field="notEnabled"
    ></b-form-select>
    <div>
<!--      Owner: {{ this.$parent.agreement_owner }}<br>-->
      Status: owner<br>
      <hr>
      ID: {{ this.$parent.user_agreements[selected].id }}<br>
      Date: {{ this.$parent.user_agreements[selected].signed }}<br>
      Valid: {{ this.$parent.user_agreements[selected].valid }}<br>
      Name: {{ this.$parent.user_agreements[selected].title }}<br>
      Currency: {{ this.$parent.user_agreements[selected].currency }}<br>
      <b-button size="sm" variant="info" class="mt-2" block @click="clients()">Clients</b-button>
    </div>
  </div>

</template>

<script>
import {checkInternet} from "@/helpers/offline";

export default {
  name: "Agreement",
  data() {
    return {
      selected: 0,
      // options: [
      //   {item: 0, name: 'Ellcom Co.Ltd [7]'},
      //   {item: 1, name: 'TestDistributor [12]'},
      // ],
      // agreements: [
      //   {id: "7", date: "2023-06-24", name: "Ellcom Co.Ltd", currency: "RUB", status: "owner"},
      //   {id: "12", date: "2023-06-24", name: "TestDistributor", currency: "EUR", status: "owner"}
      // ]
    }
  },
  methods: {
    clients() {

      checkInternet().then(resp => {
        if (!resp) {
          console.log("off, NO INTERNET")
          alert("The data isn't available in offline mode")
        } else {
          this.$parent.isLoading = true
          this.$store.dispatch("distributor/getAgreementUsers", this.$parent.user_agreements[this.selected].id).then(() => {
            this.$parent.isLoading = false
            this.$parent.showClients = true
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>