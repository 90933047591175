<template>
  <div v-if="$store.state.proto.order.step === 'placed'">

<!--    {{ $parent.hasValidAgreement }}-->
<!--    {{ $parent.isRUBAgreement }}-->
    <b-card style="min-height: 300px; overflow-y: scroll; overflow-x: hidden">
      <b-card-text>
        <span class="small-gray-card">Order:</span> {{ $parent.order_with_date() }}<br>
        <span class="small-gray-card">Vessel:</span> {{ $store.state.proto.order.ecdis_name }}<br>
        <span class="small-gray-card">IMO:</span> {{ $store.state.proto.order.ecdis_imo }}<br>
<!--        <span class="small-gray-card">License group:</span><br>-->
        <span class="small-gray-card">Customer:</span> {{ $store.state.proto.order.customer }}<br>
        <div v-if="!has_valid_agreement" >
          <div v-if="!$parent.hasValidAgreement"><span class="small-gray-card">Total order value: </span>{{ totalOrderValue }} €<br></div>
          <div v-if="$parent.hasValidAgreement && $parent.isRUBAgreement"><span class="small-gray-card">Total order value: </span>{{ totalOrderValueRUB }} ₽<br></div>
          <div v-if="$parent.hasValidAgreement && !$parent.isRUBAgreement"><span class="small-gray-card">Total order value: </span>${{ totalOrderValueUSD }}<br></div>
        </div>
        <br>
<!--        <span class="small-gray-card">Chart Database:</span><br>-->
        <span class="small-gray-card">Charts in order:</span> {{ $store.state.proto.order.cells.length }}<br>
        <span class="small-gray-card">Overview:</span> {{ $parent.cellsBands[0] }}<br>
        <span class="small-gray-card">General:</span> {{ $parent.cellsBands[1] }}<br>
        <span class="small-gray-card">Coastal:</span> {{ $parent.cellsBands[2] }}<br>
        <span class="small-gray-card">Approach:</span> {{ $parent.cellsBands[3] }}<br>
        <span class="small-gray-card">Harbour:</span> {{ $parent.cellsBands[4] }}<br>
        <span class="small-gray-card">Berthing:</span> {{ $parent.cellsBands[5] }}<br>
        <span class="small-gray-card">Updating period:</span> {{ $store.state.proto.order.period.period }}<br>
        <br>
        <div v-if="!has_valid_agreement" >
          <span class="small-gray-card">Subtotal value: </span>{{ $store.state.proto.order.total_price }} €<br>
          <span class="small-gray-card">NET value: </span>
          {{ net_review }} €<br>
          <span class="small-gray-card">VAT: </span>
          {{ vat_review }} €<br>
          <span class="small-gray-card">Fee: </span>
          {{ fee_review }} €
        </div>
        <!--          <span class="small-gray-card">Graphical view of ordered charts:</span><br>-->
      </b-card-text>

    </b-card>
    <br>
    <b-row v-if="$store.state.proto.order.step === 'placed' ">
      <b-col class="text-right">
<!--        <b-button size="sm" variant="info" @click="backToEdit()">Back</b-button>&nbsp;-->
        <!--          <b-button size="sm" variant="warning" @click="cancelOrder()">New</b-button>&nbsp;-->
        <b-button size="sm" variant="info" @click="sendOrder()">Ok</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "PrePurchaseSummary",
  props: ['is_mobile','hasValidAgreement', 'isRUBAgreement', 'cellsBands', 'payment_system', 'is_request', 'has_valid_agreement', 'order_with_date', 'net', 'fee', 'vat'],
  data(){
    return{

    }
  },
  mounted() {
  },
  methods : {
    sendOrder(){
      return this.$parent.sendOrder();
    },
    backToEdit(){
      return this.$parent.backToEdit();
    },
  },
  computed: {

    totalOrderValue(){
      return this.$store.state.proto.order.total_price;
    },
    totalOrderValueRUB(){
      return this.$store.state.proto.order.total_priceAgreementRUB
    },
    totalOrderValueUSD(){
      return this.$store.state.proto.order.total_priceAgreementUSD
    },
    net_review() {
      return Number(this.$store.state.proto.order.total_priceNet).toFixed(2)
    },

    vat_review() {
      return Number((this.$store.state.proto.order.total_priceNet)/100*24).toFixed(2)
    },

    fee_review() {
      let net = Number(this.$store.state.proto.order.total_priceNet)
      let vat = net/100*24
      return Number((net + vat)/100*7).toFixed(2)
    },
  },
  watch(){},
}
</script>

<style scoped>

</style>