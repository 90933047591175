import {myAxios, myAxiosRetry} from "@/services/myAxios";
import {file} from "paths.macro";
import store from "@/store";

const initialState = {
    distributors: [],
    agreements: [],
    agreementUsers: [],
    orders: [],
    users: [],
    selected: {
       agreement_id: '',
       agreement_name: '',
       agreement_distributor: '',
       user_id: '',
       user_email: '',
       user_device_id: '',
       order_id: ''
    },
    is_agreements_loading: false,
    is_orders_loading: false,
}

export const distributor = {
    namespaced: true,
    state: localStorage.getItem('distributor') ? JSON.parse(localStorage.getItem('distributor')) :
        initialState,
    actions: {
        getAgreements({dispatch, commit}) {
            // alert("getAgreements")
            console.log(file, "getAgreements : start")
            commit("setAgreementLoadingState", true)

            let userId = store.state.auth.user.displayId ? store.state.auth.user.displayId : '93384' //TODO

            let URL = "/api/v1/agreement/query";
            return new Promise(resolve => {
                myAxiosRetry.post(URL,
                    {"mainUserId": userId}).then(resp => {
                    console.log(file, "getAgreements: " + URL);
                    commit("setAgreements", resp.data)
                    commit("setAgreementLoadingState", false)
                    resolve()
                });
            })

        },
        getAgreementUsers({dispatch, commit}, id) {
            console.log(file, "getAgreementUsers : start")
            let URL = "/api/v1/agreement/" + id + "/users";
            return new Promise(resolve => {
                myAxios.get(URL).then(resp => {
                    console.log(file, "getAgreementUsers: " + URL);
                    commit("setAgreementUsers", resp.data)
                    resolve()
                });
            })

        },
        getUsers({dispatch, commit}, id) {
            console.log(file, "getUsers : start")
            let URL = "api/v1/agreement/distributor/" + id;
            myAxios.get(URL).then(resp => {
                console.log(file, "getUsers: " + URL);
                commit("setUsers", resp.data)
            });

        },
        getOrders({dispatch, commit}) {
            console.log(file, "getOrders : start")
            commit("setOrdersLoadingState", true)

            let URL = "/api/v1/agreement/orderlist";
            myAxios.post(URL,
                {
                    "id": 3
                }).then(resp => {
                console.log(file, "getOrders: " + URL);
                commit("setOrders", resp.data)
                commit("setOrdersLoadingState", false)
            });

        }
    },
    mutations: {
        setAgreementLoadingState(state, flag) {
            state.is_agreements_loading = flag;
        },
        setOrdersLoadingState(state, flag) {
            state.is_orders_loading = flag;
        },
        setDistributors(state, distributors) {
            state.distributors = distributors;
        },
        setAgreements(state, agreements) {
            state.agreements = agreements;
        },
        setAgreementUsers(state, agreementUsers) {
            state.agreementUsers = agreementUsers;
        },
        setUsers(state, users) {
            state.users = users;
        },
        setOrders(state, orders) {
            state.orders = orders;
        },
        setCurrentAgreement(state, payload) {
            state.selected.agreement_id = payload.id;
            state.selected.agreement_name = payload.name;
            state.selected.agreement_distributor = payload.distributor;
        },
        clearCurrentAgreement(state) {
            state.selected.agreement_id = '';
            state.selected.agreement_name = '';
            state.selected.agreement_distributor = '';
        },
        setCurrentUser(state, payload) {
            state.selected.user_id = payload.id;
            state.selected.user_email = payload.email;
            state.selected.user_device_id = payload.device_id;
        },
        clearCurrentUser(state) {
            state.selected.user_id = '';
            state.selected.user_email = '';
            state.selected.user_device_id = '';
        },
        setCurrentOrder(state, payload) {
            state.selected.order_id = payload.id;
        },
        clearCurrentOrder(state) {
            state.selected.order_id = '';
        },
    }
}