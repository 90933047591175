import {myAxios} from "@/services/myAxios";
import {eventBus} from "@/main";
import store from "@/store";

export function getDepth(coordinates) {
    let URL = 'api/v1/depth?lat=' + coordinates.lat + '&lon=' + coordinates.lng

    myAxios.get(URL).then(resp => {
        console.log(resp.data)
        eventBus.$emit('show-depth', {depth: resp.data, coordinates: coordinates})
    })
}
export function getWeather(coordinates) {
    let time = store.state.fickle.weather.datetime
    let URL = 'https://nogotiles.bgeo.fi:6011/weather_info?lat=' + coordinates.lat + '&lon=' + coordinates.lng + '&t=' + time

    myAxios.get(URL).then(resp => {
        console.log(resp.data)
        eventBus.$emit('show-weather-popup', {weather: resp.data, coordinates: coordinates, time: time})
    })
}

export function showInfo(coordinates) {
    let URL = 'api/v1/objectsinfo?lat=' + coordinates.lat + '&lon=' + coordinates.lng

    store.commit("fickle/setObjectInfoLoading", true)
    myAxios.get(URL).then(resp => {
        // console.log(resp.data)
        store.commit("fickle/setObjectInfoLoading", false)
        eventBus.$emit('show-map-info', {info: resp.data, coordinates: coordinates})
    })
}

export function deg2rad(deg) {
    return deg * (Math.PI / 180)
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    let R = 6371; // Radius of the earth in km
    let dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    let dLon = this.deg2rad(lon2 - lon1);
    let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c; // Distance in km
    return d;
}

