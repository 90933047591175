export const IS_NP = false
export const IS_GETENC = true

export const DEBUG_LOCAL_RESPONSE = false;
export const DEBUG_DOWNLOAD_RESPONSE = false;
export const DEBUG_FILTER = false;


export const CURRENT_PRODUCT = {
    is_navicom: false
};
