import {myAxios} from "@/services/myAxios";
import {file} from 'paths.macro';
import {dumpFile, getS63CKTInfo} from "@/utils/utils";
import {eventBus} from "@/main";
import store from "@/store";
// import {commit} from "lodash/seq";
import {CURRENT_PRODUCT, DEBUG_LOCAL_RESPONSE, NAVICOM} from "@/utils/build-config";
import {ActiveTab} from "@/utils/config";
import localforage from "localforage";
// import resp from "@/data/primar_countries.json";

const initialState = {
    profile:
        // localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) :
        {
        email: "",
        app_name: "",
        device_id: "",
        device_os: "",
        products: []
    },
    map: {
        url: "https://api.probki.net/map/{z}/{x}/{y}",
        ChartsFeatures: undefined,
        ChartsLayers: undefined,
        chartsLeaf: undefined
    },
    active_requests: {
        s63_orders: false,
        s63_summary: false,
    },
    s63: {
        orders: [],
        orders_list: [],
        latest_order: {
            data: null,
            isExist: false,
            id: '',
            date: '',
            isPaid: false,
            status: '',
            method: '',
        },
        ecdis_summary: [],
        summary: [],
        items: [],
        primar_map_info: [],
        primar_map_infoS102: [],
        products: {
            all: [],
            multiPart: {},
            linksToProducts: {},
            allS102: [],
            multiPartS102: {},
            linksToProductsS102: {}
        },
        countries: []
    },
}

export const charts = {
    // namespaced: true,
    state: initialState,
    getters: {
        cellById: (state) => (id) => {
            return state.s63.primar_map_info.find(chart => chart.id === id)
        },
    }

    ,
    actions: {
        loadStorage({dispatch, commit}) {
            localforage.getItem('charts').then(function(value) {
                console.log("loc", value)
                commit("setLoadedStorage", value)
            })

            // commit("setLoadedStorage")
        },
        getENCs1({commit}) {
            // alert("getENCs1")
            let url = "api/v1/primar/enc/";
            return new Promise((resolve, reject) => {

                fetch('./primar_enc.json')
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json)
                        resolve(json)
                    });

                // ----------------------------------------------
                // let resp = require("@/data/primar_enc.json")
                // let mapInfos = resp;
                // resolve(mapInfos)
                // return
                // ----------------------------------------------

                    // myAxios.get(url).then(resp => {
                    //     let mapInfos = resp.data;
                    //     resolve(mapInfos)
                    // })
            })
        },

        getENCCountries1({commit}) {
            // alert("getENCCountries1")
            commit("current/pushLoadingMarker", "charts|getENCCountries")
            let url = "api/v1/primar/countries/";

            return new Promise(resolve => {

                fetch('./primar_countries.json')
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json)
                        commit("setS63Countries", Object.freeze(json))
                        resolve()
                        return
                    });

                // ----------------------------------------------
                // let resp = require("@/data/primar_countries.json")
                // commit("setS63Countries", Object.freeze(resp))
                // resolve()
                // return
                // ----------------------------------------------


                // myAxios.get(url,             {headers: {
                //         "content-type": "application/json",
                //     }}).then((resp) => {
                //     commit("setS63Countries", Object.freeze(resp.data));
                //     resolve();
                // }).catch((error) => {
                //     console.log(error)
                //     eventBus.$emit('network-error', 102)
                // });
            })
        },

        getENCProducts1({commit, dispatch}) {
            // alert("getENCProducts1")
            commit("current/pushLoadingMarker", "charts|getENCProducts")

            let url = "api/v1/primar/products/";
            return new Promise(resolve => {

                // ----------------------------------------------
                // let resp = require("@/data/primar_products.json")

                fetch('./primar_products.json')
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json)

                let items = {};
                let itemsS102 = {};
                let links = {};
                let linksS102 = {};
                // TODO fix offset 7, 2

                let indexENC;
                let indexS102;
                json.productTypes.forEach((element, i) => {
                    console.log(i, element.id)

                    if (element.id === 1)
                        indexENC = i;

                    if (element.id === 11)
                        indexS102 = i;
                });

                let groups = json.productTypes.at(indexENC).productList.products;
                let groupsS102 = CURRENT_PRODUCT.is_navicom ? [] : json.productTypes.at(indexS102).productList.products;

                groups.forEach(x => {
                    if (x.productCellList) {
                        if (x.productCellList.productCells.length > 1) {
                            items[x.id] = x;
                        }
                        x.productCellList.productCells.forEach(y => {
                            links[y.id] = x.id
                        })
                    }
                })

                groupsS102.forEach(x => {
                    if (x.productDatasetList) {
                        if (x.productDatasetList.productDatasets.length > 1) {
                            itemsS102[x.id] = x;
                        }
                        x.productDatasetList.productDatasets.forEach(y => {
                            linksS102[y.id] = x.id
                        })
                    }
                })
                Object.keys(itemsS102).forEach(x => {
                    items[x] = itemsS102[x]
                })
                Object.keys(linksS102).forEach(x => {
                    links[x] = linksS102[x]
                })

                commit("setS63ProductsMultipart", Object.freeze(items));
                commit("setLinksToProducts", Object.freeze(links));
                commit("setAllProducts", Object.freeze(groups));
                commit("setS63ProductsMultipartS102", Object.freeze(itemsS102));
                commit("setLinksToProductsS102", Object.freeze(linksS102));
                commit("setAllProductsS102", Object.freeze(groupsS102));

                console.log('get products: end')
                commit("current/popLoadingMarker", "charts|getENCProducts")

                resolve()
                    });
                return
                // ----------------------------------------------


                myAxios.get(url).then(resp => {
                    let items = {};
                    let itemsS102 = {};
                    let links = {};
                    let linksS102 = {};
                    // TODO fix offset 7, 2

                    let indexENC;
                    let indexS102;
                    resp.data.productTypes.forEach((element, i) => {
                        console.log(i, element.id)

                        if (element.id === 1)
                            indexENC = i;

                        if (element.id === 11)
                            indexS102 = i;
                    });

                    let groups = resp.data.productTypes.at(indexENC).productList.products;
                    let groupsS102 = CURRENT_PRODUCT.is_navicom ? [] : resp.data.productTypes.at(indexS102).productList.products;

                    groups.forEach(x => {
                        if (x.productCellList) {
                            if (x.productCellList.productCells.length > 1) {
                                items[x.id] = x;
                            }
                            x.productCellList.productCells.forEach(y => {
                                links[y.id] = x.id
                            })
                        }
                    })

                    groupsS102.forEach(x => {
                        if (x.productDatasetList) {
                            if (x.productDatasetList.productDatasets.length > 1) {
                                itemsS102[x.id] = x;
                            }
                            x.productDatasetList.productDatasets.forEach(y => {
                                linksS102[y.id] = x.id
                            })
                        }
                    })
                    Object.keys(itemsS102).forEach(x => {
                        items[x] = itemsS102[x]
                    })
                    Object.keys(linksS102).forEach(x => {
                        links[x] = linksS102[x]
                    })

                    commit("setS63ProductsMultipart", Object.freeze(items));
                    commit("setLinksToProducts", Object.freeze(links));
                    commit("setAllProducts", Object.freeze(groups));
                    commit("setS63ProductsMultipartS102", Object.freeze(itemsS102));
                    commit("setLinksToProductsS102", Object.freeze(linksS102));
                    commit("setAllProductsS102", Object.freeze(groupsS102));

                    console.log('get products: end')
                    commit("current/popLoadingMarker", "charts|getENCProducts")
                    resolve();
                }).catch((error) => {
                    console.log(error)
                    eventBus.$emit('network-error', 103)
                });
            })
        },










        getENCCountries ({commit}) {
            // alert("aaaa")
            commit("current/pushLoadingMarker", "charts|getENCCountries")

            let url = "api/v1/primar/countries/";
            if (CURRENT_PRODUCT.is_navicom) {
                // url = "http://localhost:8080/dev/_navicom_countries.json";
                url = "https://service.probki.net/mkart/geocontents/mkart_gc/navicom/catalog/_navicom_countries.json";
                // url = "http://mkartweb.bgeo.fi/_navicom_countries.json";
            }

            return new Promise(resolve => {
                myAxios.get(url,             {headers: {
                    "content-type": "application/json",
                }}).then((resp) => {
                    commit("setS63Countries", Object.freeze(resp.data));
                    // commit("setS63Countries", resp.data);
                    commit("current/popLoadingMarker", "charts|getENCCountries")
                    resolve();
                }).catch((error) => {
                    console.log(error)
                    alert(error.data)
                    alert(error.toString())
                    alert(error.code)
                    eventBus.$emit('network-error', 102)
                });
            })
        },

        getENCProducts({commit, dispatch}) {
            commit("current/pushLoadingMarker", "charts|getENCProducts")

            let url = "api/v1/primar/products/";
            if (CURRENT_PRODUCT.is_navicom) {
                url = "https://service.probki.net/mkart/geocontents/mkart_gc/navicom/catalog/_navicom_products.json"
            }

            return new Promise(resolve => {
                myAxios.get(url).then(resp => {
                    let items = {};
                    let itemsS102 = {};
                    let links = {};
                    let linksS102 = {};
                    // TODO fix offset 7, 2

                    let indexENC;
                    let indexS102;
                    resp.data.productTypes.forEach((element, i) => {
                        console.log(i, element.id)

                        if (element.id === 1)
                            indexENC = i;

                        if (element.id === 11)
                            indexS102 = i;
                    });

                    let groups = resp.data.productTypes.at(indexENC).productList.products;
                    let groupsS102 = CURRENT_PRODUCT.is_navicom ? [] : resp.data.productTypes.at(indexS102).productList.products;

                    groups.forEach(x => {
                        if (x.productCellList) {
                            if (x.productCellList.productCells.length > 1) {
                                items[x.id] = x;
                            }
                            x.productCellList.productCells.forEach(y => {
                                links[y.id] = x.id
                            })
                        }
                    })

                    groupsS102.forEach(x => {
                        if (x.productDatasetList) {
                            if (x.productDatasetList.productDatasets.length > 1) {
                                itemsS102[x.id] = x;
                            }
                            x.productDatasetList.productDatasets.forEach(y => {
                                linksS102[y.id] = x.id
                            })
                        }
                    })
                    Object.keys(itemsS102).forEach(x => {
                        items[x] = itemsS102[x]
                    })
                    Object.keys(linksS102).forEach(x => {
                        links[x] = linksS102[x]
                    })


                    // commit("setS63ProductsMultipart", items);
                    // commit("setLinksToProducts", links);
                    // commit("setAllProducts", groups);
                    // commit("setS63ProductsMultipartS102", itemsS102);
                    // commit("setLinksToProductsS102", linksS102);
                    // commit("setAllProductsS102", groupsS102);
                    commit("setS63ProductsMultipart", Object.freeze(items));
                    commit("setLinksToProducts", Object.freeze(links));
                    commit("setAllProducts", Object.freeze(groups));
                    commit("setS63ProductsMultipartS102", Object.freeze(itemsS102));
                    commit("setLinksToProductsS102", Object.freeze(linksS102));
                    commit("setAllProductsS102", Object.freeze(groupsS102));

                    console.log('get products: end')
                    commit("current/popLoadingMarker", "charts|getENCProducts")
                    resolve();
                }).catch((error) => {
                    console.log(error)
                    eventBus.$emit('network-error', 103)
                });
            })
        },
        getENCs2222({dispatch, commit}) {
            let errorFlag = false

            console.log('getENCs: start', file)

            eventBus.$emit('draw-map-spinner', true);
            commit("current/setPrimarInfoStatus", true)
            commit("current/pushLoadingMarker", "charts|getENCs")

            let url = "api/v1/primar/enc/";
            if (CURRENT_PRODUCT.is_navicom) {
                url = "https://service.probki.net/mkart/geocontents/mkart_gc/navicom/catalog/_navicom_enc.json";
            }

            return new Promise((resolve, reject) => {
                let mapInfos = []

                dispatch("getENCProducts").then(() => {
                    myAxios.get(url).then(resp => {
                        mapInfos = resp.data;
                        let links = charts.state.s63.products.linksToProducts;
                        mapInfos.forEach(item => {
                            item.productId = links[item.id];
                            if (!item.productId){
                                errorFlag = true
                            }
                        })

                        commit("addPrimarMapInfos", Object.freeze(mapInfos));
                        // commit("addPrimarMapInfos", mapInfos);

                        if (errorFlag){
                            alert('PROBLEMA1, '+ file)
                            console.log('PROBLEMA, '+ file)
                        }

                        dispatch('getENCCountries').then(() => {
                            eventBus.$emit('draw-map-spinner', false);
                            commit("current/setPrimarInfoStatus", false)
                            commit("current/popLoadingMarker", "charts|getENCs")
                            commit("current/setPrimarMapInfosState", true);
                            resolve();
                        })

                        // if (CURRENT_PRODUCT.is_navicom) {
                        //     dispatch('getENCCountries').then(() => {
                        //         eventBus.$emit('draw-map-spinner', false);
                        //         commit("current/setPrimarInfoStatus", false)
                        //         commit("current/popLoadingMarker", "charts|getENCs")
                        //         commit("current/setPrimarMapInfosState", true);
                        //         resolve();
                        //     })
                        // } else {
                        //     dispatch('getS102ENCs').then(() => {
                        //         dispatch('getENCCountries').then(() => {
                        //             eventBus.$emit('draw-map-spinner', false);
                        //             commit("current/setPrimarInfoStatus", false)
                        //             commit("current/popLoadingMarker", "charts|getENCs")
                        //             commit("current/setPrimarMapInfosState", true);
                        //             resolve();
                        //         })
                        //     })
                        // }
                        console.log('getENCs: end', file)

                    }).catch((error) => {
                        console.log(error)
                        eventBus.$emit('network-error', 101)
                    });
                })
            })

        },
        addS63Order({commit, dispatch}, payload){
            let datePurchased = new Date();
            let order = {
                date_purchased: datePurchased,
                items: payload.keys,
                status: "Created",
                uuid: payload.uuid,
                uuid_short: payload.uuid.substring(0, 11) + "...",
                price: payload.price,
                type: "",
            }
            let orders = charts.state.s63.orders;
            orders.push(order);
            commit("setS63Orders", orders);
        },
        addS63Items({commit, dispatch}, keysList){
            console.log(file, "add63Items : start")
            console.log(keysList)
            return new Promise( resolve => {
                const allItems = charts.state.s63.products.all;
                const allItemsS102 = charts.state.s63.products.allS102;

                let newItems = [];
                commit("setS63Items", newItems);// dated
                keysList.forEach( key => {

                    let item = allItems.find( obj => {return obj.id == key;})
                    if (key.startsWith("102")) {
                        item = allItemsS102.find( obj => {return obj.id == key;})
                    }

                    if (!item) {
                        console.log(key)}
                    if (!newItems.find(obj => {return obj.id === key;})) {
                        newItems.push(item);
                    }
                })
                commit("setS63Items", newItems); // dated
                let CartItems = [];
                newItems.forEach(item => {
                    const countryInfo = charts.state.s63.countries.find(x => x.id === item.countryId);
                    const subTypes = countryInfo.possibleSubscriptionTypeList.possibleSubscriptionTypes;
                    let periods = []
                    subTypes.forEach( x => {
                        switch (x.id) {
                            case 0: periods.push(12); break;
                            case 1: periods.push(6); break;
                            case 4: periods.push(3); break;
                            default: break;
                        }
                    })
                    console.log(periods)

                    console.log(item)

                    CartItems.push(
                        {
                            id: item.id,
                            name: item.title,
                            cells: (item.id.startsWith("102") ? item.productDatasetList.productDatasets : item.productCellList.productCells),
                            periods: periods,
                            disabled: false
                        }
                    )
                })
                dispatch("current/addS63Cart", CartItems)
                    .then( () => {
                        console.log(file, "add63Items : end")
                        resolve();
                    })
            })
        },
        getS102ENCs({dispatch, commit}){
            return new Promise( resolve => {
                let flag = false
                    myAxios.get('api/v1/primar/dataset/').then(resp => {
                        console.log('get s102: start')
                        let mapInfosS102 = resp.data;
                        let mapInfos = charts.state.s63.primar_map_info
                        let links = charts.state.s63.products.linksToProductsS102;

                        mapInfosS102 = mapInfosS102.filter(function (e) {
                            return e.productSpecNumber !== 111;
                        });

                        mapInfosS102.forEach(chart => {
                            chart.usageBand = 's102'
                            chart.productId = links[chart.id];
                            mapInfos.push(chart);
                            if (!chart.productId){
                                flag = true

                                // console.log(item.id, links[item.id])
                            }
                        })


                        commit('addPrimarMapInfosS102', Object.freeze(mapInfos));
                        if (flag){
                            // alert('PROBLEMA2, '+ file)
                            console.log('PROBLEMA, '+ file)
                        }
                        console.log('get s102: end')
                        resolve();
                    }).catch((error) => {
                        console.log(error)
                        eventBus.$emit('network-error', 104)
                    });
            })
        },
        selectItems({dispatch}, payload) {
            let items = payload["items"];
            let type = payload["type"];

            let storage = charts.state[type];
            return new Promise(resolve => {
                dispatch('addItems', {items: items, storage: storage}).then(() => {
                    resolve()
                });

            })
        },
        addItems({commit}, payload) {
            let items = payload["items"];
            let storage = payload["storage"];

            return new Promise(resolve => {
                    let selectedCharts = storage.items.filter(function (e) {
                        return e.is_included === true;
                    });
                    commit("excludeAllItems", selectedCharts);
                    items.forEach(function (item, i, arr) {
                        let chartsSet = storage.items.filter(function (e) {
                            return e.name === item;
                        });
                        commit("includeItems", chartsSet);
                        resolve()
                    });
                }
            )
        },
        setMapsURL({commit}, URL) {
            commit("setURL", URL);
        },
        setProfile({commit}, payload) {
            commit("setProfile", payload);
            localStorage.setItem('profile', JSON.stringify(charts.state.profile));
            // console.log(charts.state.profile)
        },
        getECDISSummary({commit, dispatch}, payload) {
            console.log(file, "getECDISSummary");

            return new Promise(resolve => {
                let URL = "/api/v1/primar-charts/" + payload.device_id;
                if (payload.user_id) {
                    URL = "/api/v1/primar-charts/" + payload.device_id + "?userId=" + payload.user_id;
                }

                commit("current/pushLoadingMarker", "charts|getECDISSummary")
                myAxios.get(URL).then(resp => {
                    let summary = resp.data;
                    console.log(resp.data)
                    commit("setECDISSummary", summary);
                    resolve();
                }).catch((error) => {
                    console.log(error)
                    // eventBus.$emit('network-error', 105) //TODO
                }).finally(() => {
                        commit("current/popLoadingMarker", "charts|getECDISSummary")
                    })

            });
        },
        getS63Summary({commit, dispatch}, payload) {
            console.log(file, "getS63Summary");

            // if (charts.state.active_requests.s63_summary)
            //     return;

            // charts.state.active_requests.s63_summary = true;

            return new Promise(resolve => {
                let URL = "/api/v1/orders/summary/deviceid/" + payload.device_id;
                if (payload.user_id) {
                    URL = "/api/v1/admin/orders/summary/deviceid/" + payload.device_id + "?userId=" + payload.user_id;
                }

                // if (!charts.state.active_requests.s63_summary) {
                    commit("current/pushLoadingMarker", "charts|getS63Summary")
                    // charts.state.active_requests.s63_summary = true;
                    myAxios.get(URL).then(resp => {
                        let summary = resp.data;
                        commit("setS63Summary", summary);
                        // charts.state.active_requests.s63_summary = false;
                        resolve();
                    }).catch((error) => {
                        console.log(error)
                        eventBus.$emit('network-error', 106)
                    }).finally(() => {
                            commit("current/popLoadingMarker", "charts|getS63Summary")
                        })
                // } else {
                //     resolve();
                // }
            });
        },
        getLatestOrder({commit}, payload) {
            return new Promise(resolve => {
                // commit("current/pushLoadingMarker", "charts|getLatestOrder")

                let URL = "/api/v1/order/" + payload.order_id;
                if (payload.user_id) {
                    URL = "/api/v1/admin/order/" + payload.order_id + "?userId=" + payload.user_id;
                }

                myAxios.get(URL).then(resp => {
                    let order = resp.data;
                    commit("setLatestOrder", order);
                    // commit("current/popLoadingMarker", "charts|getLatestOrder")
                    resolve();
                }).catch((error) => {
                    console.log(error)
                    eventBus.$emit('network-error', 107)
                });
            });
        },
        getS63OrdersList({commit, dispatch}, payload) {
            console.log(file, "getS63Orders");

            if (charts.state.active_requests.s63_orders)
                return;

            charts.state.active_requests.s63_orders = true;

            return new Promise(resolve => {
                commit("current/pushLoadingMarker", "charts|getS63OrdersList")

                let URL = "/api/v1/orders/deviceid/" + payload.device_id;
                if (payload.user_id ) {
                    URL = "/api/v1/admin/orders/deviceid/" + payload.device_id + "?userId=" + payload.user_id;
                }


                myAxios.get(URL).then(resp => {
                    let orders = resp.data.orders;
                    commit("setS63OrdersList", orders);

                    // this.$store.dispatch('current/loadingOrders', false).then(() => {
                    // });
                    commit("current/popLoadingMarker", "charts|getS63OrdersList")
                    charts.state.active_requests.s63_orders = false;
                    resolve();
                }).catch((error) => {
                    console.log(error)
                    eventBus.$emit('network-error', 108)
                });
            });
        }
    },
    mutations: {
        setLoadedStorage(state, value) {
            if (!value)
                return

            let newState = JSON.parse(value)
            // state.s63.primar_map_info = null
            // state.s63.products.all = null
            // state.s63.products = null

            Object.assign(state, Object.freeze(newState))

            // Object.keys(state).forEach(key => {
            //     if (newState[key] !== undefined) {
            //         state[key] = newState[key]
            //     }
            // });
        },
        setChartsLeaf(state, payload) {
            state.map.ChartsLeaf = payload;
        },
        setChartsFeatures(state, payload) {
            // state.map.ChartsFeatures = payload;
            state.map.ChartsFeatures = Object.freeze(payload);
        },
        setChartsLayers(state, payload) {
            state.map.ChartsLayers = payload;
        },
        setS63Countries(state, payload) {
            state.s63.countries = payload;
        },
        setAllProducts(state, payload){
            state.s63.products.all = payload;
        },
        setAllProductsS102(state, payload){
            state.s63.products.allS102 = payload;
        },
        setLinksToProducts(state, payload){
            state.s63.products.linksToProducts = payload;
        },
        setLinksToProductsS102(state, payload){
            state.s63.products.linksToProductsS102 = payload;
        },
        setS63ProductsMultipart(state, payload){
            state.s63.products.multiPart = payload;
        },
        setS63ProductsMultipartS102(state, payload){
            state.s63.products.multiPartS102 = payload;
        },
        excludeAllItems(state, selectedItems) {
            for (let index in selectedItems) {
                selectedItems.at(index).is_included = false;
            }
        },
        includeItems(state, items) {
            items.at(0).is_included = true;
        },
        addPrimarMapInfos(state, PMI) {
            state.s63.primar_map_info = PMI;


            // let a = document.createElement("a");
            // let jsonString = [JSON.stringify(PMI, null, 2)];
            // let file = new Blob([jsonString], {type: 'text/plain'});
            // a.href = URL.createObjectURL(file);
            // a.download = "primarmapsinfo.json";
            // a.click();

        },
        addPrimarMapInfosS102(state, PMI) {
            state.s63.primar_map_infoS102 = PMI;


            // let a = document.createElement("a");
            // let jsonString = [JSON.stringify(PMI, null, 2)];
            // let file = new Blob([jsonString], {type: 'text/plain'});
            // a.href = URL.createObjectURL(file);
            // a.download = "primarmapsinfo.json";
            // a.click();

        },
        setURL(state, URL) {
            state.map.url = URL;
        },
        setProfile(state, payload) {
            state.profile.email = payload.email;
            state.profile.app_name = payload.app_name;
            state.profile.device_id = payload.device_id;
            state.profile.user_id = payload.user_id;
            state.profile.device_os = payload.device_os;
            state.profile.products = payload.products;
            state.profile.appState = payload.appState;

        },
        setS63Items(state, items) {
            state.s63.items = items;
        },
        setS63Orders(state, orders) {
            state.s63.orders = orders;
        },
        dropS63LatestOrder(state) {
            state.s63.latest_order.isExist = false;
        },
        setS63OrdersList(state, orders) {
            state.s63.orders_list = orders;
            state.s63.orders = [];

            if (orders.length > 0) {
                state.s63.latest_order.isExist = true;
                state.s63.latest_order.id = orders.at(orders.length - 1).orderId;
                state.s63.latest_order.date = orders.at(orders.length - 1).createdUtc;
                state.s63.latest_order.isPaid = orders.at(orders.length - 1).isPaid;
                state.s63.latest_order.status = orders.at(orders.length - 1).status;
                state.s63.latest_order.method = orders.at(orders.length - 1).paymentMethod;
            }
        },
        setECDISSummary(state, summary) {
            state.s63.ecdis_summary = summary;
        },
        setAllSummaryClear(state) {
            state.s63.latest_order = {
                data: [],
                isExist: false,
                id: '',
                date: '',
                isPaid: false,
                status: '',
                method: '',
            };
            state.s63.ecdis_summary = [];
            state.s63.orders_list = [];
            state.s63.summary = [];
        },
        setS63Summary(state, summary) {
            state.s63.summary = summary;
        },
        setLatestOrder(state, order) {
            state.s63.latest_order.data = order;

            state.s63.latest_order.isPaid = order.isPaid;
            state.s63.latest_order.status = order.status;
        }
    }
}