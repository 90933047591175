import {myAxios} from "@/services/myAxios";
import store from "@/store";
import Vue from "vue";
import VueCookies from 'vue-cookies';
import {file} from "paths.macro";
import {eventBus} from "@/main";
import router from "@/router";

export function payWithStripe(orderId, payment_method){
    window.$cookies.set("s63_payment", true);
    window.$cookies.set("s63_payment_order_id", orderId);
    window.$cookies.set("s63_payment_email", store.state.current.profile.email);

    console.log(payment_method)

    // window.open("http://localhost:8080/payment-status/success");


    myAxios
        .post("/api/v1/orders/pay/" + orderId, {
            headers: {
                "content-type": "application/json",
            },
        })
        .then((resp) => {
            console.log(resp)
            // window.open(resp.data);
            if (payment_method !== 'trial' && payment_method !== 'agreement') {
                eventBus.$emit('get-stripe-url', resp.data);
            }
        })
        .catch((error) => {
            if (payment_method !== 'trial' && payment_method !== 'agreement') {
                alert('something went wrong [1]', file)
            } else {
                // location.reload();
                router.push({name:"transactions"})
            }
        })

}