<template>
  <div>

    <b-modal v-model="showCalculateModal" title="Auto routing" @ok="okPressed" @cancel="cancelPressed">
      <p>Do you want to calculate route from {{this.routePorts[this.PORT_TYPES.START]}} to {{this.routePorts[this.PORT_TYPES.FINISH]}}?</p>
    </b-modal>

    <b-container fluid>
      <b-row>
        <b-col>
          <b-form-group>
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="searchFilter"
                  debounce="1000"
                  type="search"
                  placeholder="Type to Search"
                  @input="onStartFilter"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="light" :disabled="!searchFilter" @click="searchFilter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-show="!busyFlag">
        <b-row>
          <b-col>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table selectable select-mode="single" primary-key="name" ref="searchOnTable" :per-page="perPage" :current-page="currentPage" :items="ports" :fields="visible_fields" :filter="searchFilter" @row-selected="onRowSelected"
                     @filtered="onFiltered">

              <template #cell(start)="row">
                <div v-if="checkPort(row) && !routePorts[PORT_TYPES.START] && routePorts[PORT_TYPES.FINISH] !== row.item.name">
                  <b-button size="sm" variant="success" @click="addPort(PORT_TYPES.START, row)">
                    <b-icon icon="flag"></b-icon>
                  </b-button>
                </div>
                <div v-else>
                  <b-button v-if="routePorts[PORT_TYPES.START] === row.item.name" @click="removePort(PORT_TYPES.START)" size="sm">
                    <b-icon icon="flag"></b-icon>
                  </b-button>
                </div>
              </template>

              <template #cell(finish)="row">
                <div v-if="checkPort(row) && !routePorts[PORT_TYPES.FINISH] && routePorts[PORT_TYPES.START] !== row.item.name">
                  <b-button size="sm" variant="warning" @click="addPort(PORT_TYPES.FINISH, row)">
                    <b-icon icon="flag"></b-icon>
                  </b-button>
                </div>
                <div v-else>
                  <b-button v-if="routePorts[PORT_TYPES.FINISH] === row.item.name" @click="removePort(PORT_TYPES.FINISH)" size="sm">
                    <b-icon icon="flag"></b-icon>
                  </b-button>
                </div>
              </template>

            </b-table>
          </b-col>
        </b-row>
      </div>
      <div align="center" class="text-sm-centercenter text-danger my-2" v-show="busyFlag">
        <b-spinner small class="align-middle"></b-spinner>
        <strong>&nbsp;Searching...</strong>
      </div>
    </b-container>
  </div>
</template>

<script>
import {eventBus} from "@/main";
import {myAxios} from "@/services/myAxios";
import store from "@/store";
import Vue from "vue";

let ports = require("@/data/ports.json")

export default {
  name: "Search",
  data() {
    return {
      ports: ports,
      busyFlag: false,
      searchFilter: null,
      filterOn: ['name'],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
      routePorts: ["", ""],
      PORT_TYPES: {START: 0, FINISH: 1},
      showCalculateModal: false
    }
  },
  mounted() {
    this.totalRows = this.ports.length
  },
  methods: {
    onStartFilter() {
      this.busyFlag = true
    },
    onRowSelected(items) {
      let lat = items[0].lat
      let lon = items[0].lon
      eventBus.$emit('show-from-search', {lat: lat, lon: lon})
      this.$refs.searchOnTable.clearSelected()
      this.$root.$emit('bv::toggle::collapse', 'search-sidebar')
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.busyFlag = false;
    },
    addPort(portType, row) {
      Vue.set(this.routePorts, portType, row.item.name)
    },
    removePort(portType) {
      Vue.set(this.routePorts, portType, "")
    },
    checkPort(row) {
      let port = row.item.name

      if ((!this.routePorts[this.PORT_TYPES.START] && !this.routePorts[this.PORT_TYPES.FINISH]) ||
          (port === 'VISBY' && this.routePorts.includes('RIGA') || port === 'RIGA' && this.routePorts.includes('VISBY')) ||
          (port === 'AHUS' && this.routePorts.includes('STUDSTRUPVARKETS HAVN') || port === 'STUDSTRUPVARKETS HAVN' && this.routePorts.includes('AHUS')) ||
          (port === 'FLEETWOOD' && this.routePorts.includes('PLASSENDALEBRUG') || port === 'PLASSENDALEBRUG' && this.routePorts.includes('FLEETWOOD')) ||
          (port === 'LA BARANQUINE' && this.routePorts.includes('FREIXO, PORTO') || port === 'FREIXO, PORTO' && this.routePorts.includes('LA BARANQUINE')) ||
          (port === 'FARO' && this.routePorts.includes('ISLAS HORMIGAS') || port === 'ISLAS HORMIGAS' && this.routePorts.includes('FARO')) ||
          (port === 'GANDIA' && this.routePorts.includes('ARBATAX') || port === 'ARBATAX' && this.routePorts.includes('GANDIA')) ||
          (port === 'PORT METHANIER' && this.routePorts.includes('NAPOLI') || port === 'NAPOLI' && this.routePorts.includes('PORT METHANIER')))
        return true
      else {
        return false
      }
    },
    okPressed() {
      // this.routePorts = ["", ""]
      eventBus.$emit('show-waypoints')
      this.calculateGlobalRoute()
    },
    cancelPressed() {
      this.routePorts = ["", ""]
    },
    calculateGlobalRoute() {
      let id = ''
      if (this.routePorts.includes('VISBY'))
        id = '25ccd16e-767b-4e43-b75e-0b587b138a9b'

      if (this.routePorts.includes('AHUS'))
        id = 'b8e74016-aad6-41b7-be2a-4ebf64cae8df'

      if (this.routePorts.includes('FLEETWOOD'))
        id = 'b296bf92-6021-4f18-836f-e9170f996fb2'

      if (this.routePorts.includes('LA BARANQUINE'))
        id = 'af8b1e0b-cb05-495b-9184-df6012e87147'

      if (this.routePorts.includes('FARO'))
        id = '9efe19bf-6daf-437c-b325-ae2dec0e127f'

      if (this.routePorts.includes('GANDIA'))
        id = 'afffd978-b04a-4d9d-a515-8bd98fed0db4'

      if (this.routePorts.includes('PORT METHANIER'))
        id = '3af24c78-12fe-4b03-8b73-5d319ec46861'

      if (id) {
        // setTimeout(() => {
          this.routePorts = ["", ""]
        // }, 3000);

        myAxios.get(`http://route-builder.m-s-solutions.net/routes/` + id)
            .then(resp => {
              store.commit("fickle/setGlobalRoute", resp.data)
            });
      }
    }
  },
  computed: {
    visible_fields() {
      return [
        {key: 'name', label: 'Name', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'start', label: 'Start', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'finish', label: 'Finish', tdClass: 'small-black', thClass: 'small-gray'}
      ]
    }
  },
  watch: {
    'routePorts'() {
      if (this.routePorts[this.PORT_TYPES.START] && this.routePorts[this.PORT_TYPES.FINISH])
        this.showCalculateModal = true
    },
  }
}
</script>