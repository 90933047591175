import store from "@/store";
import {myAxios} from "@/services/myAxios";
import {eventBus} from "@/main";
import {file} from "paths.macro";
import localforage from "localforage";

// import {Offline} from "./offline.min.js"
// import "offline-js"

export function restoreRequests() {
    // console.log("off, a3")
    alert("Resent previous requests")
    console.log("off, Resent previous requests")
    store.state.fickle.offline.requests.forEach(item => {

        setTimeout(() => {
            console.log("off", item.name, item.params, item.event)

            store.state.fickle.offline.requests.pop()
            store.dispatch(item.name, item.params).then(resp => {
                eventBus.$emit(item.event)
            })
        }, 2000)
    })
}

export function checkInternetLoop() {
    console.log("off, checkInternetLoop")
    fetch('https://getenc-onboard.com/internet.txt', {mode: 'no-cors'})
        .then(response => {
            store.commit("fickle/setOfflineStatus", false)

            // console.log("off, a1")
            // console.log("off", store.state.fickle.offline.isRequestsExist)
            if (store.state.fickle.offline.isRequestsExist) {
                // console.log("off, a2")
                store.commit("fickle/clearOfflineRequestsStatus")
                restoreRequests()
            }

        })
        .catch(error => {
            store.commit("fickle/setOfflineStatus", true)
        })
}

export function checkInternet() {
    console.log("checkInternet")

    return new Promise(resolve => {
        if (!window.navigator.onLine)
            resolve(false)

        fetch('https://getenc-onboard.com/internet.txt', {mode: 'no-cors'})
            .then(response => {
                store.commit("fickle/setOfflineStatus", false)
                // eventBus.$emit('is-offline', false)

                resolve(true)
            })
            .catch(error => {
                store.commit("fickle/setOfflineStatus", true)
                // eventBus.$emit('is-offline', true)
                resolve(false)
            })
    })
}

export function loadUserInstallations() {
    console.log("off, loadUserInstallations start")

    return new Promise(resolve => {
        checkInternet().then(resp => {
            if (!resp) {
                console.log("off, NO INTERNET")
                resolve(false)
                return
            }

            store.commit("fickle/setUserInstallationsReady", true)
            store.commit("fickle/setUserInstallationsLoading", true)

            store.dispatch('proto/getInstallations').then(() => {
                console.log("off, loadUserInstallations DONE")
                store.commit("fickle/setUserInstallationsLoading", false)
                localStorage.setItem('proto', JSON.stringify(store.state.proto));
                localforage.setItem('proto', JSON.stringify(store.state.proto));
                resolve(true)
            })
        })
    })
}

export function loadUserData() {
    console.log("off, loadUserData start")

    return new Promise(resolve => {
        checkInternet().then(resp => {
            if (!resp) {
                console.log("off, NO INTERNET")
                resolve(false)
                return
            }

            store.commit("fickle/setUserDataReady", true)
            store.commit("fickle/setUserDataLoading", true)

            let userRequests = [
                store.dispatch("proto/getAgreementsV2"),
                store.dispatch("transactions/getTransactions"),
                store.dispatch("distributor/getAgreements")
            ]

            Promise.allSettled(userRequests).then(([r1, r2, r3, r4]) => {
                console.log("off, loadUserData DONE")
                store.commit("fickle/setUserDataLoading", false)
                localStorage.setItem('proto', JSON.stringify(store.state.proto));
                localforage.setItem('proto', JSON.stringify(store.state.proto));
                localStorage.setItem('transactions', JSON.stringify(store.state.transactions));
                localStorage.setItem('distributor', JSON.stringify(store.state.distributor));
                resolve(true)
            })
        })
    })
}

export function loadENCData() {
    console.log("off, loadENCData start")

    return new Promise(resolve => {
        checkInternet().then(resp => {
            if (!resp) {
                console.log("off, NO INTERNET")

                localforage.getItem('charts').then(function(value) {
                    console.log("loc", value)
                    return value
                }).catch(function(err) {
                    console.log("loc", null)
                    return null
                });

                resolve(false)
                return
            }

            store.commit("fickle/setENCDataReady", true)
            store.commit("fickle/setENCDataLoading", true)

            let encRequests = [store.dispatch("getENCCountries1"), store.dispatch("getENCProducts1"), store.dispatch("getENCs1")]
            Promise.allSettled(encRequests).then(([r1, r2, r3]) => {
                console.log("off, loadENCData DONE")

                let mapInfos = r3.value
                let errorFlag = false
                let links = store.state.charts.s63.products.linksToProducts;
                mapInfos.forEach(item => {
                    item.productId = links[item.id];
                    if (!item.productId) {
                        errorFlag = true
                    }
                })

                if (errorFlag) {
                    alert('Primar is not available, ' + file)
                    console.log('PROBLEMA, ' + file)
                }

                store.commit("addPrimarMapInfos", Object.freeze(mapInfos));
                store.commit("fickle/setENCDataLoading", false)

                // localStorage.setItem('charts', JSON.stringify(store.state.charts));
                localforage.setItem('charts', JSON.stringify(store.state.charts));

                resolve(true)
            })

        })
    })

}

export function changeOrderStatus(order_id, status) {
    console.log("off, changeOrderStatus start")

    return new Promise(resolve => {
        let name = 'proto/updateOrderStatus'
        let params = {order_id: order_id, status: status}

        checkInternet().then(resp => {
            if (!resp) {
                console.log("off, REQUEST NO INTERNET")
                store.commit("fickle/setOfflineRequest", {name: name, params: params, event: 'delayed-request-order-status'})
                resolve(false)
            } else {
                store.dispatch(name, params).then(() => {
                    resolve(true)
                })
            }
        })
    })
}

export function registerECDIS(is_update, data) {
    console.log("off, registerECDIS start")

    return new Promise(resolve => {
        let name = is_update ? 'proto/updateEcdis' : 'proto/addEcdis'
        let params = data

        checkInternet().then(resp => {
            if (!resp) {
                console.log("off, REQUEST NO INTERNET")
                store.commit("fickle/setOfflineRequest", {name: name, params: params, event: 'delayed-request-add-ecdis'})
                resolve(false)
            } else {
                store.dispatch(name, params).then(() => {
                    resolve(true)
                })
            }
        })
    })
}