import {myAxios} from "@/services/myAxios";
import {file} from "paths.macro";
// import {commit} from "lodash/seq";
import axios from "axios";
import {logout} from "@/services/authService";
import {eventBus} from "@/main";

const initialState = {
    map: {
        lat: -1,
        lon: -1,
        zoom: -1,
    },
}

export const leaflet = {
    namespaced: true,
    state: initialState,
    actions: {
    },
    mutations: {
        setMapCenter(state, coordinates) {
            state.map.lat = coordinates.lat;
            state.map.lon = coordinates.lng;
            eventBus.$emit('find-nearest-center-wp')
        },
        setMapZoom(state, zoom) {
            state.map.zoom = zoom;
        },
        resetStorage(state) {
            Object.assign(state, initialState);
        }
    }
}