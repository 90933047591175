<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mt-4">
      <b-alert variant="success" show>{{paymentStatus}}</b-alert>
<!--      <h3 class="text-center w-75"><div v-html="paymentStatus">-->

<!--        <br>-->
<!--      </div></h3>-->
    </div>

    <div class="d-flex justify-content-center align-items-center mt-4">
      <b-button variant="success" :to="{name: 'transactions'}">Return to your orders</b-button>
    </div>

<!--    <br>{{get_cookies()}}-->

  </div>
</template>

<script>
import LocLink from "@/components/LocLink";
export default {
  created() {
    console.log(JSON.parse(localStorage.getItem('selected-geocontent')), JSON.parse(localStorage.getItem('appState')))
    this.$store.commit('setSelectedGeocontent', JSON.parse(localStorage.getItem('selected-geocontent')));
    this.$store.commit('addAppState', JSON.parse(localStorage.getItem('appState')));
  },
  components: {LocLink},
  computed: {
    paymentStatus() {
      switch (this.$route.params.status) {
        case "success":
          this.$store.dispatch('current/updatePaymentStatus', {back_from_store: true, is_success: true}).then(() => {
          });
          return "Thank you. You will be notified by email on order's status change.";
        case "cancel":
          this.$store.dispatch('current/updatePaymentStatus', {back_from_store: true, is_success: false}).then(() => {
          });
          return "Sorry, your payment was rejected by processing service. Please try again or contact our customers care service.";
        default:
          this.$store.dispatch('current/updatePaymentStatus', {back_from_store: true, is_success: false}).then(() => {
          });
          return "Oups! Something goes wrong.";
      }
    },
  },
  methods: {
    keep_status() {
    },
    get_cookies() {
      let is_payment = this.$cookies.get("s63_payment");
      let order_id = this.$cookies.get("s63_payment_order_id");
      let email = this.$cookies.get("s63_payment_email");

      let x1 = this.$store.state.current.profile.user_id;
      let x2 = this.$store.state.current.profile.device_id;
      let x3 = this.$store.state.current.profile.email;

      return is_payment + " | " + order_id + " | " + email + " || " + x1 + " || " + x2 + " || " + x3;
    }
  }
};
</script>